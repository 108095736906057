.fab-lp-section {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 40px 32px;
    gap: 24px;

    width: 800px;
    /* height: 348px; */
    height: auto;

    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
}

.fab-lp-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
    margin-top: 24px;
    height: auto;
}

.fab-lp-header-btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 24px;
    /* 
    width: 720px;
    height: 40px; */
    width: 100%;
    height: auto;
}

.fab-lp-section-title {
    /* width: 266px; */
    /* height: 28px; */
    height: auto;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;

    color: #D31145;
}

.fab-lp-list-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;

    width: 720px;
    height: 196px;
}

.fab-lp-list-option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 8px 12px;
    gap: 8px;

    width: 100%;
    height: 92px;

    background: #F5F5F5;
    border-radius: 4px;
}

.fab-lp-lang {
    /* width: 51px; */
    height: 24px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-wrap: nowrap;
    text-align: right;

    color: #18181B;
}

.fab-lp-proficiency {
    /* width: 158px;
    height: 20px; */

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;

    color: #18181B;
}

.fab-lp-lang-form-container {

    /* display: flex; */
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 19px;

    /* width: 720px; */
    /* height: 803px; */
    height: auto;
    width: 100%;

    background: #FDFCFC;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    margin-top: 10px;
}

.fab-lp-lang-form-inner-container {

    /* display: flex; */
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    /* width: 672px; */
    /* height: 696px; */
    width: auto;
    height: auto;

    border: 1px solid #E5E5E5;
    border-radius: 4px;
}

.fab-lp-lang-cb-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;

    /* width: 672px;
    height: 53px; */

    background: #FFFFFF;
}

.fab-lp-lang-cb-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    /* width: 24px;
    height: 24px; */
}

.fab-lp-lang-cb {
    box-sizing: border-box;

    width: 20px;
    height: 20px;

    background: #FFFFFF;
    border: 1px solid #D4D4D4;
    border-radius: 2px;
}

.fab-lp-lang-title-wrapper {
    /* width: 604px;
    height: 24px; */

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #18181B;
}

.fab-lp-lang-title {
    /* width: 604px;
    height: 24px; */

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #18181B;
}

.fab-lp-select-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 16px 16px 48px;
    gap: 20px;

    /* width: 672px;
    height: 156px; */

    background: #FFFFFF;
}

.fab-lp-pro-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    /* width: 608px;
    height: 56px; */
}

.fab-lp-pro-title {
    /* width: 136px;
    height: 24px; */

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;

    color: #18181B;
}

.fab-lp-pro-option-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;

    /* width: 360px;
    height: 24px; */
}

.fab-lp-pro-option-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    margin: 0 auto;
    width: 79px;
    height: 24px;
}

.fab-lp-pro-option-btn {
    box-sizing: border-box;

    width: 20px;
    height: 20px;

    background: #FFFFFF;
    border: 1px solid #D4D4D4;
}

.fab-lp-pro-option-text {
    width: 26px;
    height: 24px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #18181B;
}

.fb-save-cancel-container {
    margin-top: 10px
}

@media(max-width: 400px) {
    .fab-lp-pro-option-container {
        gap: 10px;
        flex-direction: column;
    }
}

@media(max-width: 568px) {
    .fab-lp-section-title {
        font-size: 20px;
    }
}