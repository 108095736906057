.fab-sign-in-container,
.fab-sign-up-container {
    background-color: #FAFAFA;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.fab-sign-up-container {
    height: auto;
}

.fab-sign-in-container {
    height: calc(100dvh - 72px);
}

.fab-sign-in,
.fab-sign-up-wrapper {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    padding: 40px 48px;
}

.fab-sign-in {
    width: 592px;
}

.fab-sign-up-wrapper {
    width: 696px;
    margin-top: 32px;
    margin-bottom: 32px;
}

.fab-sign-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    margin-bottom: 20px;
}

.fab-sign-title {
    display: inline-block;
    font-size: 24px;
    font-family: 'Poppins', system-ui, Arial, Helvetica, sans-serif;
    font-weight: 600;
    color: #18181B;
}

.fab-sign-poweredby {
    font-size: 12px;
    font-weight: 400;
    color: #3F3F46;
}

.fab-sign-poweredby span {
    font-weight: 700;
    color: #293153;

}

.fab-sign-poweredby span:last-child {
    color: #FA4094;
}

.fab-sign-socials {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
}

.fab-sign-socials button:first-child {
    width: 100%;
    background-color: #1877F2;
    border-radius: 5px;
    color: #FFF;
}

.fab-sign-fb {
    background-color: #1877F2;
    color: #FFF;
    width: 100%;
    height: 46px;
}

.fab-sign-google {
    background-color: #FFF;
    color: #18181B;
    border: 1px solid #D4D4D4;
    width: 90%;
    height: 46px;
}

.fab-sign-divider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    color: #3F3F46;
    font-size: 16px;
    font-weight: 400;
    pointer-events: none;
}

.fab-sign-divider span:not(:nth-child(2)) {
    width: 100%;
    height: 1px;
    background-color: #E5E5E5;
    margin-top: 20px;
    margin-bottom: 20px;
}

.fab-sign-form .fab-input-wrapper {
    width: 100%;
}

.fab-sign-form .fab-input-svg {
    display: none;
}

.fab-sign-email {
    margin-bottom: 12px;
}

.fab-sign-email label,
.fab-sign-password label,
.fab-sign-name label,
.fab-sign-number label {
    color: #18181B;
    font-size: 16px;
    font-weight: 700;
}

.fab-sign-email .fab-input-container,
.fab-sign-password .fab-input-container {
    margin-top: 4px;
}

.fab-sign-confirm-password {
    margin-top: 10px;
}

.fab-sign-forgot {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: 4px;
    cursor: pointer;
    color: #D31145;
    font-weight: 400;
    font-size: 16px;
}

.fab-sign-btn {
    width: 100%;
    margin-top: 20px;
}

.fab-sign-up {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #18181B;
}

.fab-sign-up-link {
    font-weight: 700;
    color: #D31145;
    cursor: pointer;
    text-decoration: underline;
}

.fab-sign-name-number {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 12px;
}

.fab-sign-name-number div {
    width: 100%;
}

.fab-sign-name-number input[type="number"]::-webkit-inner-spin-button,
.fab-sign-name-number input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.fab-sign-name-number input[type="number"] {
    -moz-appearance: textfield;
}

.fab-number-input-wrapper {
    border: 1px solid #D4D4D4;
    border-radius: 4px;
    background-color: #FFF;
    display: flex;
    justify-content: flex-start;
}

.fab-number-input-wrapper:focus-within {
    border-color: #1D4ED8;
}

.fab-number-country {
    outline: none;
    padding-right: 8px;
    padding-top: 8px;
    padding-left: 8px;
    margin-right: 8px;
    border: none;
    border-radius: 4px 0 0 4px;
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    display: block;
    text-align: center;
    color: #3F3F46;
}

.fab-number-input {
    padding: 12px 16px;
    border: none;
    border-radius: 0 4px 4px 0;
    width: 100%;
    border-left: 1px solid #D4D4D4;
}

.fab-number-input:focus {
    outline: none;
}

.fab-sign-check-label {
    color: #18181B;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    margin-top: 28px;
    display: flex;
    align-items: flex-start;
}


#fab-sign-check {
    height: 16px;
    accent-color: #D31145;
    border: 1px solid #D4D4D4;
    appearance: none;
    -webkit-appearance: none;
    margin-right: 20px;
    margin-top: 2px;
    outline: none;
    box-sizing: border-box;
    width: 100%;
    max-width: 16px;
}

.fab-sign-privacy-terms {
    font-size: 12px;
    font-weight: 400;
    color: #71717A;
    margin-top: 8px;
}

.fab-sign-privacy-terms a {
    font-weight: 700;
    text-decoration: underline;
    font-size: 12px;
    font-weight: 400;
    color: #71717A;
    margin-top: 8px;
}

.fab-sign-privacy-terms a:hover,
.fab-sign-privacy-terms a:active {
    text-decoration: underline;
}

.fab-sign-password-requirments {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 4px;
}

.fab-sign-password-requirments span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    color: #71717A;
    font-size: 14px;
    font-weight: 400;
}

.fab-password-valid {
    color: green;
}

.fab-sign-error-alert {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    background-color: #FEF2F2;
    border: 1px solid #FECACA;
    border-radius: 4px;
    margin-bottom: 20px;
}

.fab-sign-single-error {
    color: #B91C1C;
    font-size: 16px;
    font-weight: 400;
}

.fab-sign-multiple-error span {
    font-weight: 700;
    color: #B91C1C;
    font-size: 16px;
}

.fab-sign-error-list {
    padding-left: 20px;
    font-weight: 400;
    color: #B91C1C;
    font-size: 16px;
}

.fab-input-wrapper .fab-sign-input-error {
    border-color: #B91C1C;
}

.fab-sign-forgot-link {
    color: #D31145;
}

@media(max-width: 768px) {

    .fab-sign-in,
    .fab-sign-up-wrapper {
        margin: 0 32px;
    }
}

@media(max-width: 568px) {

    .fab-sign-in,
    .fab-sign-up-wrapper {
        margin: 0;
        border: none;
        border-radius: 0;
        padding-right: 16px;
        padding-left: 16px;
    }

    .fab-sign-form {
        display: flex;
        flex-direction: column-reverse;
    }

    .fab-sign-up-wrapper .fab-sign-form,
    .fab-sign-name-number {
        flex-direction: column;
    }

    .fab-sign-socials {
        flex-direction: column;
    }

    .fab-sign-up {
        border-top: 1px solid #E5E5E5;
        padding-top: 20px;
    }

    .fab-sign-socials button {
        width: 100%;
    }
}