.fb-skills-section {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 40px 32px;
    gap: 24px;

    width: 800px;
    /* height: 192px; */
    height: auto;

    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
}

.fb-skills-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    /* width: 720px; */
    width: 100%;
    height: auto;
}

.fab-skills-form-container {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 19px;

    /* width: 720px; */
    width: 100%;

    height: 100%;

    background: #FDFCFC;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
}

.fab-skills-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    /* width: 672px; */
    width: 100%;
    height: 100%;
}

.fab-skills-form-wrapper .css-b62m3t-container,
.fab-skills-form-wrapper .css-a7zl7i-control {
    width: 100%;
}

.fab-skills-form-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    /* width: 672px; */
    width: auto;
    height: 24px;
}

.fab-skills-form-header-text {
    width: 37px;
    height: 24px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    color: #18181B;
}

.fab-skills-form-ta-wrapper {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0px;

    width: 672px;
    height: 96px;
    min-height: 96px;

    background: #FFFFFF;
    border: 1px solid #D4D4D4;
    border-radius: 4px;
}

.fab-skills-form-ta {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 0px 8px 16px;
    gap: 8px;

    /* width: 656px; */
    width: 100%;
    height: 96px;
}

.fab-skills-dp-container {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 0px;

    position: relative;
    /* width: 660px; */
    width: 100%;
    height: 255px;
    left: 0;
    top: 0;

    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}

.fab-skills-dp-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 12px;

    width: 660px;
    height: 52px;
}

.fab-skills-dp-option:hover {
    background: #F5F5F5;
    cursor: pointer;
}

.fab-skills-dp-option-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;

    width: 640px;
    height: 40px;
}

.fab-skills-dp-option-title {
    width: 640px;
    height: 24px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #18181B;
}

.fab-skills-dp-option-subtitle {
    width: 640px;
    height: 16px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #71717A;
}

.fab-skills-list-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;

    /* width: 302px; */
    /* height: 40px; */
    width: auto;
    height: auto;
    flex-wrap: wrap;
}

.fab-skills-list-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;

    /* width: 183px; */
    width: auto;
    height: 40px;

    background: #F5F5F5;
    border-radius: 4px;
}

.fab-skills-list-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    text-wrap: nowrap;

    color: #3F3F46;
}