.fab-carousel-container {
    position: relative;
    margin-top: 40px;
    margin-bottom: 48px;
}

.fab-carousel-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    padding-top: 29%;
    height: 0;
}

.fab-carousel-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
    background-size: cover;
    background-position: center;
    border-radius: 4px;
    background-repeat: no-repeat;
}

.fab-carousel-left-arrow,
.fab-carousel-right-arrow {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    background-color: #929497;
    z-index: 1;
    cursor: pointer;
    padding: 10px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    border-radius: 100%;
}

.fab-carousel-left-arrow {
    left: 16px;
}

.fab-carousel-right-arrow {
    right: 16px;
}

.fab-carousel-dot {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 12px;
}

.fab-carousel-dot div {
    height: 12px;
    width: 12px;
    background-color: #D4D4D4;
    border-radius: 100%;
    cursor: pointer;
}

.fab-carousel-dot-active {
    background-color: #D31145 !important;
}

@media(max-width: 768px) {
    .fab-carousel-container {
        margin-left: 0;
        margin-right: 0;
        margin-top: 16px;
        margin-bottom: 40px;
    }

    .fab-carousel-slide {
        border-radius: 0 !important;
    }
}

@media(max-width: 570px) {

    .fab-carousel-left-arrow,
    .fab-carousel-right-arrow {
        display: none;
    }
}