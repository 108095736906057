.fab-jobs-search-filter {
    background-color: #FFFFFF !important;
}

.fab-jobs-search-fields {
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.3fr 0.1fr;
    column-gap: 10px;
    padding: 30px 0;
}

.fab-jobs-job-feed-banner {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
}

.fab-jobs-search-fields .fab-input-container {
    width: 100%;
    height: 100%;
}

.fab-jobs-search-fields .fab-input-wrapper {
    width: 100%;
    height: 100%;
}

.fab-browse-jobs-dropdown-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1.2fr;
    column-gap: 6px;
}

.fab-browse-jobs-count {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: right;
    color: #71717A
}

.fab-jobs-search-filter {
    border-bottom: 1px solid #E5E5E5;
}

.fab-browse-jobs-title-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.fab-browse-jobs-title {
    color: #D31145;
    font-size: 24px;
    font-weight: 600px;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
}

.fab-bj-wrapper {
    background-color: #FAFAFA
}

.fab-bj-no-results {
    background-color: #FFFFFF
}

.fab-browse-jobs-container {
    padding: 40px 0;
    display: grid;
    grid-template-columns: 1fr 0.3fr;
    gap: 48px;
}

.fab-jobs-list {
    margin-top: 16px;
}


.fab-jobs-ad-container {
    display: grid;
    grid-template-rows: 1fr;
    row-gap: 16px
}

.fab-job-date {
    border: 1px solid #D4D4D4;
    border-radius: 0 0 4px 4px;
    background: #FDFCFC;
    padding: 16px 16px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.fab-job-date-text {
    color: #71717A;
    font-size: 14px;
    font-weight: 400px;
}

.fab-job-item {
    padding: 16px;
    border: 1px solid #D4D4D4;
    border-radius: 4px 4px 0 0;
    position: relative;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    box-sizing: border-box;
    background-color: #FFFFFF;
}

.fab-text-filter-content {
    background-color: #FAFAFA;
}

.fab-job-card {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.fab-browse-jobs-dropdown-container .fab-jobs-filter-icon {
    display: none;
}

.fab-job-categories-dropdown {
    display: none;
}

.fab-jobs-filter-buttons button {
    width: 100%;
}

.fab-filter-data-mobile {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #FAFAFA;
}

.fab-jobs-back-icon {
    border: none;
    background-color: inherit;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.fab-filters-reset-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    color: #D31145;
    cursor: pointer;
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
}

.fab-jobs-filter-reset {
    background: none;
    border: none;
    margin-left: 5px;
    color: #D31145;
    cursor: pointer;
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    text-wrap: nowrap;
}

.fab-filter-data-footer button {
    width: 100%;
}

.fab-filters-content {
    margin: 30px;
    overflow-y: auto;
    flex: 1;
    padding-bottom: 60px;
}

.fab-filter-data-header {
    padding: 18px 32px 18px 20px;
    border: 0px 0px 1px 0px;
    border: 1px solid #E5E5E5;
    background: #FFF;
    display: flex;
    justify-content: space-between;
}

.fab-filter-data-footer {
    text-align: center;
    border-radius: 1px 0px 0px 0px;
    border-top: 1px solid #E5E5E5;
    padding: 12px 32px 12px 32px;
    background-color: #FDFCFC;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    /* Adjust as needed to ensure it appears above other content */
    padding: 12px 32px;
    /* Adjust padding as needed */
    background-color: #FDFCFC;
    border-top: 1px solid #E5E5E5;
}

.fab-filter-header-m {
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #D31145;
}

.fab-salary-range-apply-button {
    width: 100%;
}

.fab-filter-job-categories .fab-salary-range-filter {
    padding: 0;
}

.fab-filters-subheader-m {
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #D31145;
    margin-top: 20px
}

.fab-text-filter {
    margin: 0;
    display: grid;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
}

.fab-text-filter-header {
    color: white;
    padding: 10px;
    text-align: center;
    padding: 18px 32px 18px 20px;
    border: 0px 0px 1px 0px;
    border: 1px solid #E5E5E5;
    background-color: #FDFCFC;
}

.fab-text-filter-footer {
    color: white;
    padding: 10px;
    text-align: center;
    border-radius: 1px 0px 0px 0px;
    border-top: 1px solid #E5E5E5;
    padding: 12px 32px 12px 32px;
    background-color: #FDFCFC;
}

.fab-text-filter-footer button {
    width: 100%;
}

.fab-text-filter-fields .fab-input-wrapper {
    width: 100%;
}

.fab-text-filter-fields {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10px;
    padding: 20px;
    align-items: start;
}

.fab-filter-text {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0em;
    color: #18181B;
    margin-left: 15px;
    text-align: center;
}

.fab-text-filter-button {
    display: flex;
    background-color: white;
    border: 1px solid #D4D4D4;
    font-weight: 700;
    border-radius: 4px;
    padding: 10.5px 16px;
    cursor: pointer;
}

.fab-job-card-title {
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 90%;
    flex: 1;
    box-sizing: border-box;
}

.fab-job-card-title-container {
    display: flex;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
}

.fab-job-card-location,
.fab-job-card-company,
.fab-job-card-salary {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.fab-job-card-salary {
    font-family: 'Lato' system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #D31145;
}

.fab-job-card-company,
.fab-job-card-location {
    font-family: 'Lato' system-ui, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #3F3F46;
}

.fab-job-card-description {
    padding: 12px 24px 12px 0px;
    gap: 12px;
}

.fab-job-description {
    font-family: 'Lato' system-ui, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #3F3F46;
}

.fab-job-responsibilities {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 560px
}

.fab-jobs-search-fields .fab-drodown .fab-dropdown-text {
    max-width: 151px;
}

#fab-jobs-employment-type .fab-dropdown-text {
    max-width: 200px;
}

.fab-slider-header {
    white-space: nowrap;
    position: sticky;
    top: 0
}

.fab-slider-header {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #18181B;
    background-color: #FFFFFF;
    border-bottom: 1px solid #E5E5E5;
    padding: 8px 136px 12px 136px;
    border-radius: 8px 8px 1px 0px;
    z-index: 2;
}

.fab-slider-buttons {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 12px;
    padding: 12px 16px 12px 16px;
}

.fab-slider-content {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
}

.fab-slider-buttons-container {
    position: sticky;
    bottom: 0;
    background-color: #FFFFFF;
    z-index: 2;
    padding: 16px;
}

.fab-browse-jobs-filters-sticky {
    position: sticky;
    top: 72px;
    z-index: 2;
}

.fab-job-card-scheduled {
    font-size: 16px;
    color: #047857;
    font-weight: 700;
    line-height: 24px;
    padding: 8px 12px;
    background-color: #ECFDF5;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
}

.fab-job-applied-via {
    font-weight: 400;
    font-size: 14px;
    color: #3F3F46;
    margin-top: 4px;
    margin-bottom: 4px;
}

.fab-job-applied-via span {
    font-weight: 700;
}

.fab-job-applied-expiry {
    font-weight: 400;
    font-size: 12px;
    color: #7F1D1D;
}

.fab-job-card-noschedule {
    font-size: 16px;
    font-weight: 700;
    color: #3F3F46;
    padding: 8px 12px;
    border-radius: 8px;
    background-color: #F5F5F5;
    text-align: center;
}

.fab-job-card-footer {
    align-items: center;
}


@media (max-width: 992px) {
    .fab-browse-jobs-title {
        font-size: 20px;
    }

    .fab-jobs-filter-reset {
        display: none;
    }

    .fab-jobs-search-fields {
        display: inline;
        padding: 30px;
    }

    .fab-browse-jobs-dropdown-container {
        display: grid;
        grid-template-columns: 0.14fr 1fr 1fr 1fr 1fr 1fr;
        column-gap: 6px;
        padding-top: 12px;
        overflow: auto;
    }

    .fab-jobs-filter-buttons {
        display: none;
    }


    .fab-job-categories-dropdown {
        display: flex;
    }

    .fab-browse-jobs-dropdown-container .fab-jobs-filter-icon {
        display: flex;
        cursor: pointer;
        z-index: 99
    }

    .fab-browse-jobs-dropdown-container .fab-jobs-filter-icon button {
        border: none;
        background-color: inherit;
        cursor: pointer;
    }

    .fab-jobs-container {
        display: grid;
        grid-template-columns: 1fr;
    }

    .fab-jobs-search-fields .fab-drodown .fab-dropdown-text {
        max-width: 151px;
    }

    /* .fab-job-card-title {
        width: 500px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    } */

    .fab-job-responsibilities {
        max-width: 500px
    }

    .fab-browse-jobs-container {
        grid-template-columns: 1fr;
    }

    .fab-job-card {
        width: 100%;
    }
}

@media (max-width: 868px) {
    .fab-jobs-search-fields {
        overflow: auto;
    }

    /* .fab-job-card-title {
        width: 400px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    } */
}

@media (max-width: 647px) {
    /* .fab-job-card-title {
        width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    } */

    .fab-job-card-footer {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 16px;
    }

    .fab-job-card-noschedule,
    .fab-job-card-scheduled {
        width: 100%;
    }
}

@media (max-width: 575px) {
    /* .fab-job-card-title {
        width: 263px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    } */

    .fab-browse-jobs-title-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width: 404px) {
    /* .fab-job-card-title {
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    } */

    .fab-job-date button {
        padding: 6.5px 10px;
        font-size: 12px;
    }

    .fab-browse-jobs-title-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width: 355px) {
    .fab-job-date-text {
        font-size: 12px;
    }

    .fab-job-responsibilities {
        max-width: 100px
    }
}

@media (min-width:378px) and (max-width: 756px) {
    .fab-jobs-search-fields {
        grid-template-columns: repeat(1, 1fr);
    }

    .fab-job-responsibilities {
        max-width: 300px
    }
}

@media (max-width: 375px) {
    .fab-page-button {
        padding: 14px 14px;
    }
}

@media (min-width:356px) and (max-width: 378px) {
    .fab-job-responsibilities {
        max-width: 250px
    }
}


@media (min-width:993px) and (max-width:1448px) {
    .fab-jobs-search-fields {
        display: grid;
        grid-template-columns: 0.5fr 1.4fr 0.29fr 0.2fr;
        column-gap: 6px;
        padding: 30px 0;
    }

    .fab-browse-jobs-dropdown-container {
        display: grid;
        grid-template-columns: 0.7fr 0.7fr 0.9fr;
        column-gap: 6px;
    }

    .fab-jobs-search-fields .fab-drodown .fab-dropdown-text {
        max-width: 147px;
    }
}