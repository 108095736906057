.fab-home-latestjobs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(394px, 1fr));
    grid-gap: 16px;
}

.fab-latestjob-item {
    padding: 16px;
    border: 1px solid #D4D4D4;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    background-color: #FFFFFF;

}

.fab-latestjob-item:hover {
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
}

.fab-latestjob-bookmark {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 24px;
}

.fab-latestjob-bookmark path {
    stroke: #3F3F46;
    stroke-width: 1.5px;
}

.fab-latestjob-bookmark-active path {
    fill: #D31145;
    stroke: #D31145;
}

.fab-job-details {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 16px;
}

.fab-job-company {
    font-size: 12px;
    color: #71717A;
    font-weight: 400;
}

.fab-job-title {
    font-size: 16px;
    font-weight: 700;
    color: #18181B;
    width: 306px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fab-job-salary {
    font-size: 16px;
    color: #D31145;
    font-weight: 700;
}

.fab-job-location {
    font-size: 14px;
    color: #3F3F46;
    font-weight: 400;
}

.fab-job-pills {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.fab-job-pill-item {
    padding: 4px 8px;
    background-color: #E9EAEA;
    font-weight: 700;
    font-size: 12px;
    color: #3F3F46;
    border-radius: 4px;
}

@media(max-width: 1024px) {
    .fab-home-latestjobs {
        grid-template-columns: 1fr 1fr;
    }
}

@media(max-width: 920px) {
    .fab-home-latestjobs {
        grid-template-columns: 1fr 1fr;
    }

    .fab-job-title {
        width: 250px;
    }
}

@media(max-width: 868px) {
    .fab-job-title {
        width: 200px;
    }
}


@media(max-width: 568px) {
    .fab-home-latestjobs {
        grid-template-columns: 1fr;
    }
}