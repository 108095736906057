.fab-user-change-password-container {
    height: calc(100dvh - 100px);
}

.fab-cp-wraper {
    background-color: #FAFAFA;
}

.fab-change-wrapped {
    display: grid;
    grid-template-columns: .3fr 1fr;
    gap: 32px;
}

@media(max-width: 992px) {
    .fab-change-wrapped {
        grid-template-columns: 1fr;

    }
}

@media(max-width: 768px) {
    .fab-change-wrapped {
        grid-template-columns: 1fr;
    }
}

.fab-user-change-password {
    background: #FFF;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    padding: 20px 40px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.fab-user-change-password h3 {
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    color: #D31145;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
}

.fab-user-change-subtext {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 18px;
    color: #18181B;
    font-weight: 700;
    margin-bottom: 24px;
}

.fab-user-change-fields {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
}

.fab-user-change-fields .fab-input-close,
.fab-user-change-fields .fab-input-svg {
    display: none;
}

.fab-user-change-fields .fab-input-container {
    max-width: 448px;
}

.fab-user-change-fields .fab-input-wrapper {
    width: 100%;
}

.fab-user-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
}

.fab-error-alert-active {
    display: flex;
}

@media(max-width: 568px) {
    .fab-user-change-password-container {
        height: auto;
        margin-bottom: 24px;
    }

    .fab-user-change-password-container .fab-applied-wrapped {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .fab-user-change-password {
        border-left: none;
        border-right: none;
        padding-left: 16px;
        padding-right: 16px;
    }

    .fab-user-buttons button {
        width: 100%;
    }
}