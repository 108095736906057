.fab-applied-position {
    flex-shrink: 0;
    color: #fff;
}

.fab-aj-wrapper {
    background-color: #FAFAFA;
}

.fab-saved-applied-jobs-container {
    background-color: #FAFAFA;
}

.fab-applied-wrapped {
    display: grid;
    grid-template-columns: .3fr 1fr;
    gap: 32px;
    margin-top: 34px;
    margin-bottom: 50px;
    min-height: 100vh;
    flex: 1;
    background-color: #FAFAFA;
}

.fab-user-sidebar-options {
    background-color: #FAFAFA;
}

.fab-side-user-menu {
    background-color: #FAFAFA;
}

.fab-applied-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fab-applied-title-wrapper h3 {
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #D31145;
}

.fab-applied-cards {
    margin-top: 25px;
}

.fab-applied-content .fab-job-card-title {
    max-width: 650px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 4px;
}

.fab-applied-company {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.fab-applied-company-infos {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 12px;
}

.fab-applied-jobdesc {
    margin-top: 20px;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.fab-user-sidebar-options-m {
    display: none;
}

.fab-applied-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media(max-width: 992px) {
    .fab-applied-content .fab-job-card-title {
        max-width: 550px;
    }

    .fab-applied-wrapped {
        grid-template-columns: 1fr;

    }
}

@media(max-width: 992px) {
    .fab-user-sidebar-options-m {
        display: block;
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 8px;
    }

    .fab-user-sidebar-options {
        display: none;
    }
}

@media(max-width: 850px) {
    .fab-applied-content .fab-job-card-title {
        max-width: 450px;
    }
}

@media(max-width: 768px) {
    .fab-applied-content .fab-job-card-title {
        max-width: 450px;
    }

    .fab-applied-wrapped {
        grid-template-columns: 1fr;
    }

    .fab-applied-title-wrapper h3 {
        font-size: 20px;
    }
}


@media(max-width: 546px) {
    .fab-applied-content .fab-job-card-title {
        max-width: 400px;
    }
}

@media(max-width: 480px) {
    .fab-applied-content .fab-job-card-title {
        max-width: 280px;
        padding-right: 20px;
    }

    .fab-applied-title-wrapper {
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
    }

    .fab-applied-title-wrapper button {
        width: 100%;
    }
}

@media(max-width: 356px) {
    .fab-applied-content .fab-job-card-title {
        max-width: 230px;
        padding-right: 20px;
    }
}