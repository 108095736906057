.fab-resume-section {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 40px 32px;
    gap: 24px;

    width: 800px;
    /* height: 477px; */
    height: auto;

    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
}

.fab-resume-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    /* width: 720px;
    height: 60px; */
    width: 100%;
    height: auto;
}

.fab-resume-header-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;

    /* width: 720px; */
    width: 100%;
    height: 28px;
}

.fab-resume-header {
    /* width: 100px; */
    /* height: 28px; */
    height: auto;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;

    color: #D31145;
}

.fab-resume-desc {
    /* width: 477px;
    height: 24px; */
    width: 100%;
    height: auto;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    text-align: left;

    color: #3F3F46;
}

.fab-upload-resume-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    /* width: 720px;
    height: 112px; */
    width: 100%;
    height: auto;
}

.fab-upload-resume-header {
    width: 143px;
    height: 24px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-wrap: nowrap;

    color: #18181B;
    white-space: nowrap;
}

.fab-upload-resume-desc {
    /* width: 227px; */
    width: 100%;
    height: 40px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #3F3F46;
}

.fab-resume-select-file-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    /* width: 720px; */
    width: 100%;
    height: 40px;
}

.fab-resume-select-file-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 12px;
    height: 40px;
    border-radius: 4px 0px 0px 4px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 30px;
}

.fab-resume-select-file-input-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #A1A1AA;
}

.fab-resume-select-file-container input[type="file"] {
    display: none;
}

.fab-resume-browse-btn-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    width: 114px;
    height: 40px;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: #D4D4D4;
    border-radius: 0px 4px 4px 0px;
    background: #FFFFFF;
}

.fab-resume-browse-icon {
    width: 16px;
    height: 16px;
}

.fab-resume-browse-btn {
    width: 54px;
    height: 19px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    color: #D31145;
}

.fab-upload-resume-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 12px;

    width: 165px;
    height: 40px;

    background: #D31145;
    border-radius: 4px;
}

.fab-upload-resume-btn-disabled {
    opacity: 0.4;
}

.fab-upload-resume-btn-text {
    width: 133px;
    height: 19px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #FFFFFF;
}

.fab-upload-resume-btns-space {
    width: 16px;
    height: 16px;
}

.fab-upload-resume-divider-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;

    /* width: 720px; */
    width: 100%;
    height: 24px;
}

.fab-upload-resume-divider {
    /* width: 336.5px; */
    width: 100%;
    height: 1px;

    background: #E5E5E5;
}

.fab-upload-resume-or {
    width: 15px;
    height: 24px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    text-align: center;

    color: #3F3F46;
}

.fab-generate-resume-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 8px;
    /* 
    width: 720px;
    height: 40px; */
    width: 100%;
    height: auto;
}

.fab-generate-resume-text {
    /* width: 407px;
    height: 24px; */
    width: 100%;
    height: auto;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    /* text-align: center; */
    text-align: left;

    color: #3F3F46;
}

.fab-generate-resume-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 12px;

    width: 180px;
    height: 40px;

    background: #D31145;
    border-radius: 4px;
}

.fab-generate-resume-btn-text {
    width: 148px;
    height: 19px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-wrap: nowrap;

    color: #FFFFFF;
    white-space: nowrap;
}

.fab-resume-list-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 11px;
    width: 100%;
    height: auto;
    background: #F5F5F5;
    border-radius: 4px;
}

.fab-resume-list-file-icon-wrapper {
    width: 32px;
    height: 32px;
}

.fab-resume-list-file-name-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    height: auto;
    overflow: hidden;
}

.fab-resume-list-file-name-text {
    width: auto;
    height: auto;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #1D4ED8;
    display: block;
    max-width: 100%;
    word-break: break-all;
}

.fab-resume-list-remove-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 12px;

    width: 90px;
    height: 32px;

    border-radius: 4px;
    background: #F5F5F5;
}

.fab-resume-list-remove-icon {
    width: 20px;
    height: 20px;
}

.fab-resume-list-remove-text {
    width: 58px;
    height: 19px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #D31145;
}

.fab-selected-file-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    gap: 8px;

    position: relative;
    max-width: 200px;
    height: 32px;
    left: 0px;
    top: 0px;

    background: #F5F5F5;
    border-radius: 4px;
}

.fab-selected-file-icon {
    width: 12px;
    height: 12px;
}

.fab-selected-file-name {
    height: 24px;
    width: 100%;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    text-align: right;

    color: #3F3F46;

    white-space: nowrap;
    overflow: hidden;
}

.fab-selected-file-remove-icon {
    width: 12px;
    height: 12px;
}

.fab-upload-resume-wrapper {
    display: flex;
    width: 100%;
    gap: 16px;
}

.fab-upload-resume-input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    width: 100%;
    height: 40px;

    border: 1px solid #D4D4D4;
    border-radius: 4px;
}

.fab-resume-text-wrapper {
    width: 100%;
    border: 1px solid #D4D4D4;
    border-radius: 4px 0px 0px 4px;
    cursor: pointer;
    height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fab-resume-result-container {
    display: flex;
    align-items: center;
    padding: 16px;
    gap: 11px;
    background-color: #F5F5F5;
    border-radius: 4px;
    margin-top: 24px;
    margin-bottom: 24px;
}

.fab-resume-svg-text {
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: flex-start; */
    gap: 11px;
}

@media(max-width: 678px) {
    .fab-resume-select-file-input-text {
        max-width: 100px;
    }
}

@media(max-width: 478px) {
    .fab-upload-resume-wrapper {
        flex-direction: column;
    }

    .fab-upload-resume-btn {
        width: 100%;
    }

    .fab-resume-list-file-name-text {
        max-width: 100%;
    }
}

@media(max-width: 375px) {
    .fab-generate-resume-wrapper {
        flex-direction: column;
    }

    .fab-generate-resume-btn {
        width: 100%;
    }
}

@media(max-width: 568px) {
    .fab-resume-header {
        font-size: 20px;
    }

    .fab-resume-list-remove-text {
        display: none;
    }

    .fab-resume-list-file-name-text {
        font-size: 14px;
    }
}