.fab-share-buttons-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.fab-share-button {
    background-color: #e0e0e0;
    border: none;
    color: white;
    padding: 15px;
    font-size: 20px;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fab-share-button svg {
    font-size: 20px;
}

.fab-share-button.telegram {
    background-color: #0088cc;
}

.fab-share-button.whatsapp {
    background-color: #25D366;
}

.fab-share-button.facebook {
    background-color: #3b5998;
}

.fab-share-button.generic {
    background-color: #4CAF50;
}

.st-btn[data-network='facebook'] {
    display: inline-block;
}

#st-1 .st-btn[data-network='facebook'] {
    display: inline-block !important;
}

#st-1 .st-btn[data-network='whatsapp'] {
    display: inline-block !important;
}