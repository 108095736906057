.job-ad-detail-container {
    margin-top: 40px;
    margin-bottom: 68px;
}

.fab-job-ad-detail-wrapper {
    background-color: #FFFFFF;
}

.fab-main-container {
    background-color: #FAFAFA;
}

.fab-job-ad-detail {
    display: grid;
    grid-template-columns: 1fr .5fr;
    grid-gap: 32px;
}

.fab-latest-job-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 20px;
}

.fab-job-ad-detail-wrapper {
    border: 1px solid #E5E5E5;
    border-radius: 8px;
}

.fab-job-ad-info {
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    gap: 9px;
    border-bottom: 1px solid #E5E5E5;
    position: relative;
}

.fab-job-ad-title {
    font-size: 20px;
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    color: #18181B;
    font-weight: 500;
    max-width: calc(100% - 24px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
}

.fab-job-ad-salary,
.fab-job-ad-location,
.fab-job-ad-schedule {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.fab-job-ad-salary {
    font-weight: 700;
    color: #D31145;
    font-size: 16px;
}

.fab-job-ad-location,
.fab-job-ad-schedule {
    font-weight: 400;
    color: #3F3F46;
    font-size: 16px;
}

.fab-jobs-ad-tags {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.fab-jobs-ad-pill {
    padding: 4px 8px;
    background-color: #E9EAEA;
    font-size: 12px;
    color: #3F3F46;
    font-weight: 700;
    border-radius: 4px;
}

.fab-job-ad-dates {
    display: flex;
    flex-direction: column;
    align-items: 4px;
    justify-content: flex-start;
    align-items: flex-start;
}

.fab-job-ad-date,
.fab-job-ad-expiry {
    font-size: 12px;
    color: #71717A;
    font-weight: 400;
}

.fab-job-ad-descriptions-wrapper {
    padding: 20px 24px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 100%;
}

.fab-job-ad-description-title {
    font-size: #18181B;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 9px;
}

.fab-job-ad-wrapper {
    margin-bottom: 20px;
}

.fab-job-ad-label {
    font-size: 16px;
    font-weight: 700;
    color: #18181B;
    margin-bottom: 4px;
    margin-top: 20px;
}

.fab-job-ad-text {
    font-size: 16px;
    font-weight: 400;
    color: #18181B;
}

.fab-job-ad-list {
    padding-left: 25px;
}

.fab-job-ad-detail-side {
    border-radius: 8px;
    border: 1px solid #E5E5E5;
    padding: 20px;
    height: fit-content;
    top: 100px;
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    background-color: #FFFFFF;
}

.fab-job-ad-detail-employer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}

.fab-job-ad-image {
    width: 40px;
    height: 40px;
    border-radius: 4px;
}

.fab-job-ad-detail-buttons,
.fab-job-ad-detail-buttons button {
    width: 100%;
}

.fab-job-ad-detail-buttons {
    margin-top: 12px;
}

.fab-apply-btn-mobile-container,
.fab-job-ad-detail-save-mobile {
    display: none;
}

.fab-job-btn-apply {
    margin-top: 8px;
}

.fab-job-ad-company {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    color: #18181B;
}

.fab-job-ad-brn {
    font-size: 14px;
    font-weight: 400;
    color: #71717A;
}

.fab-job-ad-detail-save {
    stroke: #3F3F46;
    stroke-width: 2;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}

.fab-latest-jobs-title {
    font-size: 20px;
    color: #D31145;

}

.job-detail-header-container {
    display: flex;
    justify-content: space-between;
}

.fab-job-ad-job-ttl {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    color: #3F3F46;
    font-size: 16px;
    margin-left: -10px;
}

.fab-job-ad-job-ttl svg {
    min-width: 40px;
    flex-shrink: 0;
}

.fab-job-ad-see-all-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    color: #D31145;
    cursor: pointer;
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    text-decoration: underline;
}

@media(max-width: 868px) {
    .fab-main-container {
        padding-bottom: 70px;
    }

    .fab-job-btn-apply,
    .fab-job-ad-detail-save {
        display: none;
    }

    .fab-job-ad-detail-buttons {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .fab-job-ad-detail-buttons button:first-child {
        display: block;
        width: fit-content;
    }

    .fab-job-ad-detail {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .fab-job-ad-detail-wrapper,
    .fab-job-ad-detail-side {
        border: none;
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .fab-job-ad-detail-side {
        border-top: 1px solid #E5E5E5;
        border-bottom: 1px solid #E5E5E5;
        padding: 20px 24px;
    }

    .job-ad-detail-container {
        margin-top: 25px;
        margin-bottom: 25px;
        position: relative;
    }

    .fab-job-ad-info {
        padding-top: 0;
    }

    .fab-apply-btn-mobile-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        position: fixed;
        bottom: 0;
        padding: 12px 32px;
        background-color: #FFF;
        z-index: 1;
        width: 100%;
        border-top: 1px solid #E5E5E5;
    }

    .fab-job-btn-apply-m {
        width: 100%;
    }

    .fab-job-ad-detail-save-mobile {
        stroke: #3F3F46;
        stroke-Width: 2;
        cursor: pointer;
        display: inline-block;
    }

    .fab-latest-jobs-title {
        font-size: 16px;
        color: #D31145;
    }
}

@media(max-width: 568px) {
    .fab-job-ad-detail {
        margin-left: 0;
        margin-right: 0;
    }

    .fab-job-ad-detail-side {
        padding-left: 16px;
        padding-right: 16px;
    }

    .fab-job-ad-detail-buttons button:first-child {
        width: 100%;
    }
}

@media(max-width: 420px) {
    .fab-job-ad-descriptions-wrapper {
        max-width: 380px;
    }
}

@media(max-width: 380px) {
    .fab-job-ad-descriptions-wrapper {
        max-width: 300px;
    }
}