.fab-location-selection-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.60);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 400;
}

.fab-location-selection-modal-wrapper {
    top: 181px;
    left: 372px;
    padding: 32px 32px 40px 32px;
    border-radius: 8px;
    background: #FFFFFF;
    border: 1px solid #F5F5F5;
    box-shadow: 0px 10px 10px 0px #0000000A;
    box-shadow: 0px 20px 25px -5px #0000001A;
    width: 592px;
}

.fab-selection-header-wrapper {
    display: flex;
    justify-content: space-between;
}

.fab-selection-header-text {
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #18181B;
}

.fab-selection-close-icon {
    cursor: pointer;
    margin-top: -20px;
    margin-right: -15px;
}

.fab-selection-sub-heading {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #18181B;
    margin-top: 16px;
}

.fab-selected-location-content-wrapper {
    border-radius: 4px;
    height: 350px;
    background: #FAFAFA;
    border: 1px solid #E5E5E5;
    margin-top: 16px;
    overflow: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.fab-selected-count {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #18181B;
}

.fab-selected-location-item {
    gap: 16px;
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #18181B;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
}

.fab-selected-location-buttons {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
}

.fab-location-cancel-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    color: #D31145;
    cursor: pointer;
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
}

.fab-selected-location-check {
    height: 20px;
    width: 20px;
    accent-color: #D31145;
}

.fab-selected-locations-error {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #DC2626;
}