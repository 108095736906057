.fab-salary-range-filter {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    box-sizing: content-box;
    margin-top: 15px;
}

.fab-jobs-salary-range-container {
    display: flex;
    flex-direction: column;
}

.fab-jobs-salary-input {
    width: 109px;
    padding: 10px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 10px;
    font-style: italic;
    font-size: 14px;
}

.fab-salary-range-filter input[type="number"]::-webkit-inner-spin-button,
.fab-salary-range-filter input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.fab-salary-range-filter input[type="number"] {
    -moz-appearance: textfield;
}

.fab-jobs-apply-button {
    background-color: #d32f2f;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
}

.fab-jobs-salary-separator {
    font-size: 18px;
    padding: 0 10px;
}

.fab-jobs-apply-button:hover {
    background-color: #b71c1c;
}

@media(max-width: 992px) {
    .fab-jobs-salary-input {
        width: 100%;
    }

    .fab-salary-range-filter {
        padding: 16px;
    }

    .fab-jobs-salary-range-container {
        display: none;
    }
}