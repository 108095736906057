.fab-search-filters {
    background-color: #E9EAEA;
}

.fab-filter-fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr .6fr;
    column-gap: 12px;
    padding: 40px 0;
}

.fab-filter-fields .fab-input-wrapper {
    width: 100%;
    height: 100%;
}

.fab-homepage-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

.fab-homepage-title {
    font-size: 24px;
    color: #D31145;
    font-weight: 600;
}

.fab-home-positions {
    margin-bottom: 64px;
}

.fab-positions {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
    grid-gap: 12px;
}

.fab-position-item {
    padding: 10px 24px;
    border-radius: 4px;
    border: 1px solid #D4D4D4;
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: flex-start;
    transition: all 250ms ease-in-out;
    max-height: 56px;
    height: 56px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    color: #3F3F46;
}

.fab-position-item:hover {
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
}

.fab-filter-buttons button {
    width: 100%;
}

.fab-homepage-button-m {
    display: none;
}

.fab-home-filters-sticky {
    position: sticky;
    top: 88px;
    z-index: 2;
}

.fab-homepage .fab-dropdown-mobile-menu .fab-location-mobile-active,
.fab-homepage .fab-dropdown-mobile-menu .fab-employment-status-mobile-active {
    display: flex;
}

@media(max-width: 992px) {
    .fab-filter-fields {
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px;
    }

    .fab-filter-fields .fab-input-container {
        grid-column: span 2;
    }

    .fab-filter-buttons {
        grid-column: span 2;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .fab-filter-buttons button {
        width: fit-content;
    }
}

@media(max-width: 768px) {
    .fab-positions {
        grid-template-columns: repeat(3, 1fr);
    }

    .fab-location-mobile-active,
    .fab-employment-status-mobile-active {
        display: flex !important;
    }

    .fab-location-mobile-active .fab-menu-mobile-container {
        max-height: 80dvh;
    }

    .fab-employment-status-mobile-active .fab-menu-mobile-container {
        max-height: 400px;
    }

    .fab-location-mobile-active .fab-menu-mobile-items {
        max-height: 470px;
        padding-bottom: 30px;
    }
}

@media(max-width: 570px) {
    .fab-positions {
        grid-template-columns: repeat(2, 1fr);
    }

    .fab-filter-fields {
        grid-template-columns: 1fr;
    }

    .fab-filter-fields .fab-input-container {
        grid-column: span 1;
    }

    .fab-filter-buttons {
        grid-column: span 1;
    }

    .fab-filter-buttons button {
        width: 100%;
        font-size: 18px;
    }

    .fab-homepage-title-wrapper button {
        display: none;
    }

    .fab-homepage-button-m {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
    }
}