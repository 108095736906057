.fab-header {
    border-bottom: 1px solid #E5E5E5;
    top: 0;
    background: #FFF;
    z-index: 5;
    transition: all 0.3s ease-in-out;
}

.fab-header-sticky {
    position: sticky;
}

.fab-header-wrapper,
.fab-header-logo-nav,
.fab-header-nav,
.fab-header-button-wrapper {
    display: flex;
    align-items: center;
}

.fab-header-wrapper {
    justify-content: space-between;
    padding: 16px 0;
}

.fab-header-logo-nav,
.fab-header-nav {
    justify-content: flex-start;
}

.fab-header-button-wrapper {
    justify-content: flex-end;
    gap: 12px;
}

.fab-header-logo-nav {
    gap: 24px;
}

.fab-header-nav {
    gap: 20px;
}

.header-nav-item {
    color: #D31145;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    transition: 250ms ease-in-out;
}

.header-nav-item,
.active {
    color: #D31145;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    transition: 250ms ease-in-out;
    text-decoration: none;
}

.header-nav-item:hover,
.header-nav-item--active {
    text-decoration: underline;
}

.header-nav-item.active,
.header-nav-item:hover {
    text-decoration: underline !important;
}

.fab-hamburger-menu {
    display: none;
    cursor: pointer;
    padding: 10px;
    background-color: transparent;
    border: none;
}

.fab-hamburger-menu:focus {
    outline: none;
}

.fab-bar {
    width: 30px;
    height: 4px;
    background-color: #D31145;
    margin: 6px 0;
    transition: 0.4s;
}

.fab-hamburger-menu.open .fab-bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.fab-hamburger-menu.open .fab-bar:nth-child(2) {
    opacity: 0;
}

.fab-hamburger-menu.open .fab-bar:nth-child(3) {
    transform: rotate(45deg) translate(-7px, -9px);
}

.no-scroll {
    overflow: hidden;
}

.fab-header-nav-mobile,
.fab-hambuerger-container {
    display: none;
    z-index: 999;
}

.fab-header-user-login {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #ffffff;
    padding: 6px 8px;
    border-radius: 8px;
    gap: 8px;
    cursor: pointer;
    position: relative;
    transition: all 250ms ease-in-out;
}

.fab-header-user-login:hover,
.fab-header-user-login:active {
    background-color: #F5F5F5 !important;
}

.fab-header-user-avatar {
    border-radius: 100%;
    height: 28px;
    width: 28px;
}

.fab-header-user-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
}

.fab-header-user-image {
    box-sizing: border-box;
    width: 28px;
    height: 28px;
    border-radius: 360px;
}

.fab-header-user-name {
    max-width: 120px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fab-header-user-menu {
    position: absolute;
    background: #ffffff;
    top: 100%;
    right: 0;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    z-index: 2;
    width: 170px;
}

.fab-header-user-menu-active {
    display: flex;
}

.fab-header-user-menu-item {
    padding: 12px 16px;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    color: #18181B;
}

.fab-header-user-menu-item:last-child {
    color: #DC2626;
    border-top: 1px solid #E5E5E5;
}

.fab-header-user-menu-item:hover {
    background-color: #F5F5F5;
}

@media(max-width: 868px) {

    .fab-header-button-wrapper,
    .fab-header-user-login {
        display: none;
    }

    .fab-hamburger-menu,
    .fab-hambuerger-container {
        display: block;
    }

    .fab-header-nav {
        display: none;
        position: absolute;
        top: 89px;
        left: 0;
        width: 100%;
        height: 100dvh;
        bottom: 0;
        background-color: #ffffff;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        z-index: 2;
    }

    .fab-header-nav.active {
        display: block;
    }

    .fab-header-nav a {
        display: block;
        padding: 15px;
        color: #333333;
        text-decoration: none;
        transition: background-color 0.3s;
    }

    .fab-header-nav a:hover {
        background-color: #f4f4f4;
    }

    .fab-nav-items-m {
        border-top: 1px solid #E5E5E5;
        border-bottom: 1px solid #E5E5E5;
    }

    .header-nav-item-m {
        font-weight: 400;
    }

    .header-nav-item-m:hover {
        text-decoration: underline;
        background-color: #FAFAFA !important;
        /* width: fit-content; */
    }

    .fab-nav-buttons-m {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        padding-top: 16px;
        padding-bottom: 28px;
    }

    .fab-nav-buttons-m button {
        width: 343px;
    }

    .fab-header-user-item-m {
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        font-size: 16px;
        color: #18181B;
        font-weight: 400;
    }

    .fab-header-user-item-m:last-child {
        flex-direction: column;
        align-items: flex-start;
        color: #DC2626;
        border-top: 1px solid #E5E5E5;
    }

    .fab-header-user-item-m:last-child span {
        font-size: 14px;
        color: #71717A;
    }
}

@media(max-width: 375px) {
    .fab-nav-buttons-m button {
        width: 100%;
    }
}