.fab-job-ad-location-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.60);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 400;
}

.fab-location-modal-wrapper {
    top: 181px;
    left: 372px;
    padding: 32px 32px 40px 32px;
    border-radius: 8px;
    background: #FFFFFF;
    border: 1px solid #F5F5F5;
    box-shadow: 0px 10px 10px 0px #0000000A;
    box-shadow: 0px 20px 25px -5px #0000001A;
    width: 696px;
}

.fab-location-modal-wrapper h3 {
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #18181B;
}

.fab-location-modal-close {
    cursor: pointer;
    margin-top: -15px;
    margin-right: -15px;
}

.fab-location-modal-container-close {
    display: none;
}

.fab-locations-header-wrapper {
    display: flex;
    justify-content: space-between;
}

.fab-location-content {
    border-radius: 4px;
    height: 350px;
    background: #FAFAFA;
    border: 1px solid #E5E5E5;
    margin-top: 16px;
    overflow: auto;
}

.fab-location-item {
    padding: 8px 8px 8px 16px;
    gap: 16px;
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #18181B;
}

@media(max-width: 740px) {
    .fab-location-modal-wrapper {
        width: 500px;
    }
}