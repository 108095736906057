.fab-forgot-password {
    height: calc(100dvh - 150px);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 80px;
}

.fab-fp-wrapper {
    background-color: #FAFAFA;
}

.fab-forgot-texts {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    max-width: 592px;
    width: 100%;
    margin-bottom: 40px;
}

.fab-forgot-texts h2 {
    font-weight: 600;
    font-size: 36px;
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    color: #18181B;
    margin-bottom: 16px;
}

.fab-forgot-texts p {
    text-align: center;
    color: #18181B;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.fab-forgot-password .fab-input-wrapper {
    width: 100%;
}

.fab-forgot-password .fab-input-container {
    margin-top: 4px;
}

.fab-forgot-password label {
    margin-bottom: 4px;
    color: #18181B;
    font-weight: 700;
    font-size: 16px;
}

.fab-forgot-buttons {
    width: 100%;
    margin-top: 32px;
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fab-forgot-login {
    width: 100%;
    text-align: center;
    color: #18181B;
    font-weight: 400;
    font-size: 16px;
}

.fab-forgot-login a {
    text-decoration: underline;
    color: #1D4ED8;
    padding-left: 4px;
    cursor: pointer;
    font-weight: 700;
}

@media(max-width: 768px) {
    .fab-forgot-texts h2 {
        font-size: 32px;
    }
}

@media(max-width: 568px) {
    .fab-forgot-password {
        margin-top: 24px !important;
    }
}