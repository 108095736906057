.fab-pagination-container {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    margin-top: 16px;
    list-style: none;
}

.fab-pagination-container a {
    padding: 16px;
    cursor: pointer;
    font-family: 'Lato' system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #71717A;
}

.fab-page-active a {
    color: #D31145;
    border-bottom: 2px solid #D31145;
    padding-bottom: 15px;
}

.fab-pagination-disabled {
    opacity: 0.4;
}

.fab-prev-icon {
    padding-top: 2px;
    display: inline-block;
}

.fab-next-icon {
    margin-top: -5px;
    display: inline-block;
}