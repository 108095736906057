.fab-sidebar-item {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    background-color: #FAFAFA;
    transition: all 250ms ease-in-out;
    color: #3F3F46;
    font-weight: 400;
    font-size: 16px;
    text-wrap: nowrap;
}

.fab-sidebar-item:hover {
    background-color: #F5F5F5;
}

.fab-sidebar-item-active {
    border-left: 6px solid #D31145;
    background-color: #F5F5F5;
    font-weight: 700;
    color: #18181B;
}

.fab-sidebar-logout {
    color: #DC2626;
}

.fab-user-sidebar-options {
    position: sticky;
    top: 80px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 0;
}

.fab-sidebard-mobile-dropdown {
    position: relative;
}

.fab-sidebard-dropdown {
    border: 1px solid #D4D4D4;
    border-radius: 4px;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
}

.fab-sidebard-dropdown span {
    font-size: 16px;
    font-weight: 400;
    color: #18181B;
}

.fab-sidebar-dropdown-options {
    background-color: #FFF;
    border: 1px solid #D4D4D4;
    border-radius: 4px;
    position: absolute;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: 100%;
    display: none;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    z-index: 2;
    box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.05), 0px 4px 6px -1px rgba(0, 0, 0, 0.10);
}

.fab-sidebar-dropdown-item {
    padding: 16px;
    width: 100%;
    color: #3F3F46;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}

.fab-sidebar-dropdown-item-active {
    background-color: #F5F5F5;
    border-left: 6px solid #D31145;
}

.fab-sidebar-dropdown-item:hover {
    background-color: #F5F5F5;
}

.fab-sidebar-dropdown-open {
    display: flex;
}

@media(max-width: 992px) {
    .fab-side-user-menu {
        display: none;
    }
}