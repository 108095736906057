.fab-job-success-content {
    display: flex;
    align-items: center;
    margin-top: 26px;
    justify-content: center;
}

.fab-job-apply-message {
    margin-top: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 24px;
}

.fab-job-apply-info-text {
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #18181B;
}

.fab-job-apply-info-sub-text {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #3F3F46;
}

.fab-job-apply-method {
    padding: 40px;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 8px;
    margin: 40px;
}

.fab-job-apply-method-title {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
}

.fab-job-apply-method-name {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #059669;

}