.fab-banner-image-container {
    position: relative;
    overflow: hidden;
    padding-bottom: 30%;
    height: 0;
}

.fab-banner-image {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle;
}

.fab-items-menu {
    position: absolute;
    width: 100%;
    height: 56px;
    left: 0px;
    background-color: #F1EEEB;
}

.fab-item-tab {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    height: 56px;
    border-bottom: 1px solid #E5E5E5;
}

.fab-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 304px;
    height: 56px;
    color: #71717A;
    cursor: pointer;
}

.fab-item.active {
    box-sizing: border-box;
    border-bottom: 3px solid #D31145;
    cursor: pointer;
    color: #D31145;
}

.fab-section {
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 56px;
}

.fab-section-container iframe {
    width: 100%;
    height: 400px;
}

.fab-section-container {
    margin-left: 112px;
    margin-right: 112px;
    margin-top: 100px;
}

.fab-cpp-jobs {
    margin-left: 112px;
    margin-right: 112px;
    margin-bottom: 30px;
}


.fab-section-image {
    width: 450px;
    height: 450px;
    background-color: #D9D9D9;
}

.fab-section-image-m {
    display: none;
}

.fab-section-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
}

.fab-section-text-header {
    width: 156px;
    height: 28px;
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #D31145;
}

.fab-section-text-description {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #18181B;
    white-space: pre-wrap;
}

.fab-section-wju {
    top: 150px;
}

.fab-section-benefits {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 112px 40px;
    gap: 32px;
    background-color: #F1EEEB;
    margin-top: 56px;
    width: 100%
}

.fab-section-benefits-header {
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #D31145;
}

.fab-section-benefits-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
}

.fab-section-benefits-row-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    gap: 16px;
}

.fab-section-benefits-row-section-icon {
    width: 96px;
    height: 96px;
    background-color: #D9D9D9;
    border-radius: 360px;
}

.fab-section-benefits-row-section-text {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #18181B;
}

.fab-employer-content {
    margin-bottom: 20px;
    min-height: 100vh;
    flex: 1;
}

.fab-company-banner {
    position: relative;
    overflow: hidden;
    padding-bottom: 30%;
    height: 0;
}

.fab-company-banner-image {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;

}

.fab-company-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
}

.fab-company-header {
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
    text-align: left;
    color: #D31145;
    margin-top: 20px;
}

.fab-company-number {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #18181B;
}

.fab-company-profile {
    margin-bottom: 50px;
}

.fab-employer-jobs-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 90px;
    margin-bottom: 56px;
}

.fab-company-jobs-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.fab-employer-jobs-header {
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    font-size: 24px;
    display: flex;
    justify-content: center;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #D31145;
}

.fab-employer-jobs-no-results {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #18181B;
    display: flex;
    justify-content: center;
}

.fab-employer-jobs-count {
    margin-right: 5px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: right;

    color: #71717A;
}

@media(max-width: 992px) {
    .fab-section-image {
        width: 342px;
        height: 342px;
        background-color: #D9D9D9;
    }

    .fab-section-container {
        margin-left: 32px;
        margin-right: 32px;
    }

    .fab-cpp-jobs {
        margin-left: 32px;
        margin-right: 32px;
    }

    /* .fab-company-banner-image {
        height: 230.84px;
    } */

    /* .fab-banner-image {
        height: 230.84px;
    } */
}

@media(max-width: 765px) {
    .fab-section-container {
        margin-left: 16px;
        margin-right: 16px;
    }

    .fab-cpp-jobs {
        margin-left: 16px;
        margin-right: 16px;
    }

    .fab-section {
        display: flex;
        flex-direction: column;
        padding: 0px;
        gap: 56px;
    }

    .fab-section-image {
        width: 100%;
        height: 375px;
        background-color: #D9D9D9;
    }

    .fab-section-image-m {
        display: flex;
    }

    .fab-section-image-desktop {
        display: none;
    }

    .fab-section-benefits {
        padding: 32px 40px;
        gap: 32px;
    }

    /* .fab-company-banner-image {
        height: 112.71px;
    } */

    .fab-company-header {
        font-size: 24px;
        text-align: center;
    }

    /* .fab-banner-image {
        height: 112.71px;
    } */
}

@media(max-width: 500px) {
    .fab-items-menu {
        overflow-x: auto;
        white-space: nowrap;
    }

    .fab-item-tab {
        flex-wrap: nowrap;
    }

    .fab-item {
        min-width: 185px;
    }
}

@media(max-width: 609px) {
    .fab-section-benefits-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Display two columns */
        gap: 24px;
    }
}