.fb-mobile-content {
    height: calc(100dvh - 470px);
}


.fb-mobile-content {
    display: flex;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 30px;
    flex-direction: column;
    row-gap: 40px;
}

.fb-mobile-header-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 16px;
}

.fb-mobile-header {
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
    color: #18181B;
}

.fb-mobile-input-wrapper {
    border: 1px solid #D4D4D4;
    border-radius: 4px;
    background-color: #FFF;
    display: flex;
    justify-content: flex-start;
}

.fb-mobile-label {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #18181B;
}

.fb-mobile-number-country-wrapper {
    align-items: center;
    display: flex;
}

.fb-number-country-icon {
    font-size: 16px;
    margin-left: 10px;
}

.fb-number-country {
    outline: none;
    padding-left: 8px;
    margin-right: 8px;
    border: none;
    border-radius: 4px 0 0 4px;
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #3F3F46;
}

.fb-mobile-input {
    padding: 12px 16px;
    margin-right: 40px;
    border: none;
    border-radius: 0 4px 4px 0;
    width: 100%;
    border-left: 1px solid #D4D4D4;
}

.fb-mobile-input:focus {
    outline: none;
}

.fb-mobile-input-wrapper input[type="number"]::-webkit-inner-spin-button,
.fb-mobile-input-wrapper input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.fb-mobile-input-wrapper input[type="number"] {
    -moz-appearance: textfield;
}

.fb-mobile-sign-up {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    text-align: center;
    color: #FFFFFF;
    padding-left: 40px;
    padding-right: 40px;
}

.fab-mobile-header-subheading {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #18181B;
    word-wrap: break-word;
    width: 100%;
    max-width: 500px;
}

.fb-mobile-divider {
    height: 2px;
    background-color: #F3F3F3;
    width: 100%;
    margin: 0 auto;
}

@media(max-width: 569px) {
    .fb-mobile-content {
        margin: 40px 20px;
    }

    .fb-mobile-sign-up {
        margin-bottom: 20px;
    }
}

@media(max-width: 390px) {
    .fb-mobile-header {
        font-size: 30px;
    }
}

@media only screen and (max-width: 1024px) {
    .fb-mobile-divider {
        width: 100%;
    }
}

/* For mobile devices */
@media only screen and (max-width: 600px) {
    .fb-mobile-divider {
        width: 100%;
    }
}