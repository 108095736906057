.fab-progressive-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 170px;
    padding: 0 40px;
    gap: 40px;
    overflow-y: auto;
}

.fab-progressive-info-container {
    display: flex;
    flex-direction: column;
    gap: 10px
}

.fab-progressive-info-header {
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    font-size: 24px;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #18181B;
}

.fab-progressive-info-name {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    text-align: left;
    color: #3F3F46;
}

.fab-progressive-info-text {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #3F3F46;
}


.fab-progressive-application-info-button {
    position: fixed;
    bottom: 0;
    padding: 16px 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #E5E5E5;
    background-color: #FFF;
    width: -webkit-fill-available;
}