.fab-company-container {
    display: grid;
    grid-template-columns: 1fr .3fr;
    grid-gap: 32px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.fab-comapnies {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.fab-company-card {
    border: 1px solid #D4D4D4;
    border-radius: 8px;
    cursor: pointer;
}

.fab-company-image-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 7px 7px 0px 0px;
}

.fab-company-image {
    width: 100%;
    padding-bottom: 75%;
    height: 0;
    background-size: cover;
    background-position: center;
}

.fab-companies-header {
    margin-bottom: 20px;
}

.fab-company-card-details {
    padding: 12px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.fab-company-card:hover {
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
}

.fab-company-listing-filters {
    background-color: #E9EAEA;
}

.fab-company-filter-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 56px
}

.fab-company-filter-wrapper h1 {
    font-size: 48px;
    font-weight: 600;
    color: #18181B;
    font-family: 'Poppins', system-ui, sans-serif;
}

.fab-company-filter-wrapper p {
    font-size: 16px;
    font-weight: 400;
    color: #3F3F46;
    max-width: 500px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 32px;
    line-height: 22px;
}

.fab-company-filter-wrapper .fab-filter-fields {
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 0;
    display: grid;
    grid-template-columns: 1fr .3fr;
}

.fab-comapny-list-container .fab-dropdown-container {
    display: none;
}

.fab-company-latest-jobs {
    margin-top: 8px;
    height: 84px;
    max-width: 84px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    flex-direction: column;
}

.fab-company-latest-job-item {
    display: grid;
    grid-template-columns: .1fr 1fr;
    gap: 8px;
}

.fab-company-latest-job-item span {
    display: block;
    width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    font-weight: 700;
    color: #3F3F46;
}

.fab-company-details-wrapper {
    display: inline;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.fab-company-btn-see-more {
    width: 100%;
    text-align: right;
    padding: 12px;
}

.fab-company-btn-see-more a {
    font-size: 16px;
    font-weight: 700;
    color: #D31145;
    text-decoration: underline;
    cursor: pointer;
}

.fab-company-details-wrapper .fab-featured-company {
    margin-top: 4px;
}

.fab-comapnies-result-text {
    font-size: 20px;
    font-weight: 500;
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    color: #18181B;
    padding-top: 24px;
}

.fab-companies-filters-desktop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 32px;
}

.fab-company-filter-checklist {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-left: 12px;
    margin-top: 16px;
}

.fab-company-filter-checklist label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    color: #18181B;
}

.fab-company-filter-checklist input[type="checkbox"] {
    accent-color: #D31145;
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.fab-comapny-filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.fab-comapny-filter-header h4 {
    color: #D31145;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Poppins', system-ui, Arial, sans-serif;
}

.fab-company-alphabet-filters,
.fab-company-cuisine-filters {
    width: 100%;
}

.fab-comapny-filter-header svg {
    cursor: pointer;
}

.fab-companies-filter-text-label {
    font-size: 24px;
    font-weight: 600;
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    color: #D31145;
}

.fab-comapny-filter-sticky {
    border-bottom: 1px solid #E5E5E5;
    position: sticky;
    top: 88px;
    background: #FFF;
    z-index: 2;
    background-color: #E8E3DD;
    transition: all 0.3s ease-in-out;
}

.fab-no-jobs-to-disp-text {
    width: 264px;
    height: 20px;

    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #71717A;
}

.fab-dropdown-mobile-menu {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.60);
    z-index: 10;
    height: 100dvh;
    display: flex;
    align-items: flex-end;
    display: none;
}

.fab-cuisine-mobile-active,
.fab-alphabet-mobile-active {
    display: flex;
}

.fab-menu-mobile-container {
    max-height: 500px;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    position: relative;
}

.fab-menu-mobile-buttons {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 12px 16px;
    background-color: #FFF;
}

.fab-menu-mobile-buttons button {
    width: 100%;
}

.fab-menu-header-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-bottom: 1px solid #E5E5E5;
}

.no-scroll {
    overflow: hidden;
}

.fab-menu-mobile-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 28px;
    padding: 12px 16px;
    overflow-x: scroll;
    max-height: 330px;
}

.fab-menu-mobile-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    cursor: pointer;
}

.fab-alphabet-cuisine-select-mobile {
    display: none;
}

.fab-reset-filters-button-desktop {
    background: none;
    border: none;
    margin-left: 5px;
    color: #D31145;
    cursor: pointer;
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
}

.fab-companies-subtext {
    display: none;
}

.fab-companies-pagination {
    margin-bottom: 20px;
}

@media(max-width: 1260px) {
    .fab-company-latest-job-item span {
        width: 164px;
    }
}

@media(max-width: 1100px) {
    .fab-company-latest-job-item span {
        width: 130px;
    }

    .fab-company-container {
        grid-template-columns: 1fr .2fr;
    }
}

@media(max-width: 1025px) {
    .fab-comapnies {
        grid-template-columns: 1fr 1fr;
    }

    .fab-company-latest-job-item span {
        width: 200px;
    }
}

@media(max-width: 992px) {
    .fab-alphabet-cuisine-select-mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        grid-column: span 2;
        overflow: scroll;
    }

    .fab-comapny-list-container {
        background-color: #FAFAFA;
    }

    .fab-alphabet-mobile,
    .fab-cuisine-mobile {
        padding: 12px 16px;
        border: 1px solid #D4D4D4;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
        background-color: #FFF;
        border-radius: 4px;
    }

    .fab-alphabet-dropdown-text-m,
    .fab-cuisine-dropdown-text-m {
        background-color: #FBE7EC;
    }

    .fab-alphabet-content,
    .fab-cuisine-content {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
    }

    .fab-company-listing-filters {
        background-color: #FFF;
        border-bottom: 1px solid #E5E5E5;
    }

    .fab-company-container {
        grid-template-columns: 1fr;
    }

    .fab-comapnies {
        grid-template-columns: 1fr 1fr;
    }

    .fab-company-latest-job-item span {
        width: 260px;
    }

    .fab-comapny-list-container .fab-dropdown-container {
        display: block;
        padding-left: 0;
        padding-right: 0;
    }

    .fab-company-filter-wrapper .fab-filter-fields {
        padding-left: 0;
        padding-right: 0;
        grid-template-columns: 1fr 1fr;
    }

    .fab-comapny-list-container .fab-filter-buttons,
    .fab-company-filter-wrapper h1,
    .fab-company-filter-wrapper p,
    .fab-companies-filters-desktop {
        display: none;
    }

    .fab-company-filter-wrapper .fab-input-container {
        grid-column: span 2;
    }

}

@media(max-width: 768px) {
    .fab-alphabet-content .fab-dropdown-text-m {
        padding-right: 10px;
        font-size: 14px
    }

    .fab-cuisine-content .fab-dropdown-text-m {
        padding-right: 10px;
        font-size: 14px
    }

    .fab-company-latest-job-item span {
        width: 200px;
    }

    .fab-company-filter-wrapper {
        padding-top: 12px;
    }

    .fab-company-filter-wrapper .fab-filter-fields {
        padding-bottom: 12px;
    }

    .fab-dropdown-menu,
    .fab-comapny-list-container .fab-dropdown-container {
        display: none;
    }

    .fab-alphabet-mobile,
    .fab-cuisine-mobile {
        padding: 10px 8px;
        border: 1px solid #D4D4D4;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
        background-color: #FFF;
        border-radius: 4px;
    }

    .fab-alphabet-dropdown-text-m,
    .fab-cuisine-dropdown-text-m {
        background-color: #FBE7EC;
    }

    .fab-alphabet-content,
    .fab-cuisine-content {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
    }

    .fab-alphabet-select-count,
    .fab-cuisine-select-count {
        border-radius: 50%;
        background-color: #D31145;
        width: 16px;
        height: 16px;
        font-size: 12px;
        text-align: center;
        color: #FFF;
        display: none;
    }

    .fab-alphabet-select-count-active,
    .fab-cuisine-select-count-active {
        display: inline-block;
    }

}

@media(max-width: 768px) {
    .fab-company-listing-filters {
        background-color: #FFF;
        border-bottom: 1px solid #E5E5E5;
    }
}

@media(max-width: 600px) {
    .fab-comapnies {
        grid-template-columns: 1fr;
    }

    .fab-company-latest-job-item span {
        width: 350px;
    }

    .fab-company-filter-wrapper .fab-filter-fields {
        grid-template-columns: 1fr;
    }

    .fab-company-filter-wrapper .fab-input-container {
        grid-column: span 1;
    }

    .fab-company-container {
        padding-top: 16px;
    }

    .fab-alphabet-cuisine-select-mobile {
        grid-column: span 1;
    }
}

@media(max-width: 450px) {
    .fab-company-latest-job-item span {
        width: 230px;
    }
}