.fab-modal-container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.60);
    z-index: 1000;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fab-modal-container-close {
    display: none;
}

.fab-modal-wrapper {
    background-color: #FFF;
    margin-bottom: 300px;
    padding: 32px;
    border-radius: 8px;
    position: relative;
    width: 45%;
    align-items: center;
}

.fab-modal-close {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
}

.fab-modal-wrapper h3 {
    font-weight: 600;
    font-size: 24px;
    color: #18181B;
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    margin-bottom: 12px;
    width: 100%;
}

.fab-modal-subtext {
    font-size: 16px;
    font-weight: 400;
    color: #18181B;
    margin-bottom: 20px;
}

.fab-modal-content {
    margin-top: 20px;
}

.fab-modal-method-radios {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.fab-modal-method-radios label {
    width: 100%;
    padding: 16px 20px;
    background-color: #FFF;
    border: 1px solid #D4D4D4;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.fab-modal-method-radios span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.fab-modal-method-radios input[type="radio"] {
    height: 20px;
    width: 20px;
    accent-color: #D31145;
    cursor: pointer;
}

.fab-metho-radio-active {
    background-color: #FEF2F2 !important;
    border-color: #D31145 !important;
}

.fab-method-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    width: 100%;
}

.fab-method-buttons button {
    max-width: 240px;
    width: 100%;
}

@media(max-width: 992px) {
    .fab-modal-wrapper {
        width: 65%;
    }
}

@media(max-width: 768px) {
    .fab-modal-wrapper {
        width: 100%;
    }

    .fab-modal-container {
        padding-left: 36px;
        padding-right: 36px;
    }
}

@media(max-width: 568px) {
    .fab-modal-wrapper {
        width: 100%;
        margin-bottom: 0;
        position: absolute;
        bottom: 0;
        padding: 0;
        border-radius: 8px 8px 0px 0px;
    }

    .fab-modal-container {
        padding: 0;
        height: 100dvh;
        margin-bottom: 0;
        position: fixed;
        bottom: 0;
    }

    .fab-modal-method-radios {
        flex-direction: column;
    }

    .fab-modal-wrapper h3 {
        width: 100%;
        text-align: center;
        font-size: 16px;
        border-bottom: 1px solid #E5E5E5;
        margin-top: 24px;
        padding-bottom: 12px;
    }

    .fab-modal-content {
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 16px;
    }

    .fab-modal-subtext {
        padding-left: 16px;
        padding-right: 16px;
        display: block;
    }

    .fab-method-buttons button {
        max-width: 100%;
    }
}