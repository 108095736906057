.fab-footer {
    background-color: #D31145;
}

.fab-footer-contact-item {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
    font-weight: bold;
}

.fab-footer-contact-item .icon {
    width: 14px;
    height: 14px;
    margin-right: 10px;
    color: white;
}

.fab-footer-contact-item a {
    color: white;
}

.fab-footer-contact-item a {
    color: white;
    text-decoration: none;
}

.fab-footer-contact-item a:hover {
    text-decoration: underline;
}

.fab-footer-contact-links {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 5px;
}

.fab-footer-details {
    display: flex;
    justify-content: space-between;
    padding-top: 54px;
    padding-bottom: 40px;
}

.fab-footer-text-about {
    max-width: 296px;
    margin-top: 15px;
    color: #FFF;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
}

.fab-footer-sitemaps {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.fab-footer-title {
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 700;
    color: #D4D4D4;
    text-transform: uppercase;
}

.fab-footer-link-item {
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
    font-weight: bold;
}

.fab-footer-copyright {
    border-top: 1px solid #FFFFFF;
    padding: 9px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fab-footer-copyright-text {
    font-size: 14px;
    font-weight: 400;
    color: #D4D4D4;
}

.fab-footer-copyright-text span {
    font-weight: 700;
    color: #FFFFFF
}

.fab-footer-link-item:hover,
.fab-footer-link-item--active {
    text-decoration: underline;
}

@media (min-width:569px) and (max-width: 992px) {
    .fab-footer-details {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: auto;
        gap: 32px
    }

    .fab-footer-contact-info {
        grid-column: 3;
        grid-row: 2;
        margin-top: -90px;
    }

    .fab-footer-text-about {
        max-width: 209px;
    }
}

@media(max-width: 568px) {
    .fab-footer-details {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 32px
    }
}