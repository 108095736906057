.fab-profile-container {
    display: flex;
    min-height: 100vh;
    margin-bottom: 250px;
}

.fab-profile-wrapper {
    background-color: #FAFAFA;
}

.fab-profile-vertical-bar {
    width: 6px;
    height: 392px;
    margin-top: 40px;
    background: #D31145;
    position: sticky;
    top: 80px
}

.fab-profile-sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
    left: 6px;
    top: 0;
    border-radius: 0px;
}

.fab-profile-sidebar-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 12px;
    width: 250px;
    height: 56px;
    margin-right: 0px;
}

.fab-profile-layout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 280px;
    margin-top: 40px;
    gap: 20px;
    width: 100%
}

.fab-profile-sidebar-profile {
    background: #F5F5F5;
}

.fab-profile-sidebar-icon {
    width: 20px;
    height: 20px;
}

.fab-profile-sidebar-profile-text {
    width: 320px;
    height: 24px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #18181B;
}

.fab-profile-sidebar-tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px 8px 48px;
    gap: 12px;
    width: 250px;
    height: 48px;
    background: #F5F5F5;
}

.fab-profile-sidebar-item {
    /* width: 320px; */
    height: 24px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #71717A;
    display: inline-block;
    margin-right: 10px;
    width: auto;
}

.fab-profile-sidebar-text {
    width: 320px;
    height: 24px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #3F3F46;
}

.fab-profile-sidebar-logout-text {
    color: #DC2626;
}

.fab-profile-section {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 40px 32px;
    gap: 24px;
    width: 800px;
    height: 910px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
}

.fab-profile-section-header {
    /* width: 120px;
    height: 28px; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-wrap: nowrap;
    color: #D31145;
    white-space: nowrap;
}

.fab-profile-section-picture-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 294px;
    height: 120px;
}

.fab-profile-section-picture-header {
    width: 101px;
    height: 24px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    text-wrap: nowrap;
    color: #18181B;
    white-space: nowrap;
}

.fab-profile-section-picture-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;
    width: 294px;
    height: 80px;
}

.fab-profile-section-picture {
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    border: 1px solid #E5E5E5;
    border-radius: 360px;
}

.fab-profile-custom-picture-container {
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    border: 1px solid #E5E5E5;
    border-radius: 360px;
}

.fab-profile-section-picture-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 12px;
    width: 190px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #D31145;
    border-radius: 4px;
}

.fab-profile-section-picture-button:hover {
    cursor: pointer;
}

.fab-profile-section-picture-button-text {
    width: 158px;
    height: 19px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-wrap: nowrap;
    color: #D31145;
    white-space: nowrap;
}

.fab-profile-section-textfield-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 448px;
    height: 68px;
}

.fab-profile-section-field-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
    height: 24px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #18181B;
}

.fab-profile-section-field-header-optional {
    width: 63px;
    height: 24px;
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #71717A;
}

.fab-profile-section-textfield-input {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    width: 448px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D4D4D4;
    border-radius: 4px;
}

.fab-profile-section-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;
    height: auto;
    margin-top: 24px;
    margin-bottom: 24px;
}

.fab-profile-section-row-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    /* width: 352px;
    height: 68px; */
    width: 100%;
}

.fab-profile-section-row-textfield-header {
    width: 105px;
    height: 24px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-wrap: nowrap;
    color: #18181B;
}

.fab-profile-section-row-input-wrapper-success {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D4D4D4;
    border-radius: 4px;
}

.fab-profile-section-row-input-wrapper-error {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    /* width: 352px; */
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #DC2626;
    border-radius: 4px;
}

.fab-profile-section-row-textfield-input {
    padding: 8px 16px;
    outline: none;
}

.fab-profile-section-row-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
    gap: 12px;
    position: relative;
    width: 54px;
    height: 38px;
    right: 5px;
    bottom: 0px;
    background: #FFFFFF;
}

.fab-profile-section-row-button-text {
    width: 38px;
    height: 20px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-decoration-line: underline;
    color: #DC2626;
}

.fab-profile-section-dropdown-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 448px;
    height: 68px;
    margin-top: 24px;
}

.fab-profile-section-dropdown-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    /* width: 448px; */
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D4D4D4;
    border-radius: 4px;
}

.fab-dropdown {
    width: 100%;
    height: 100%;
}

.fab-profile-section-gender-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 34px;
    width: 264px;
    height: 40px;
}

.fab-profile-section-gender-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    width: 115px;
    height: 40px;
}

.fab-profile-section-gender-input {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2px;
    gap: 8px;
    isolation: isolate;
    width: 24px;
    height: 24px;
}

.fab-profile-section-gender-text {
    width: 35px;
    height: 24px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #18181B;
}

.fab-profile-section-dob-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    width: 100%;
    height: 40px;
    background: #FFFFFF;
}

.fab-profile-section-dob-container>input[type="date"]::-webkit-calendar-picker-indicator {
    background-image: url('/src/assets/svg/date-picker.svg');
    width: 25px;
    height: 25px;
    padding: 12px;
    position: relative;
    left: 100%;
    right: 0;
    top: 0;
    bottom: 0;
}

.fab-profile-section-dob-input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    width: 100%;
    height: 40px;
    border: 1px solid #D4D4D4;
    border-radius: 4px;
}

.fab-profile-section-dob-input-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* color: #A1A1AA; */
    text-transform: uppercase;
}

.fab-profile-section-textarea-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 100%;
    height: 134px;
    margin-top: 24px;
}

.fab-profile-section-textarea-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 8px 16px;
    /* width: 720px; */
    width: 100%;
    height: 96px;
    min-height: 96px;
    background: #FFFFFF;
    border: 1px solid #D4D4D4;
    border-radius: 4px;
}

.fab-profile-section-textarea-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* color: #A1A1AA; */
}

.fab-profile-section-update-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0px;
    gap: 8px;
    /* width: 720px; */
    width: 100%;
    height: 40px;
}

.fab-profile-section-update-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 12px;
    width: 146px;
    height: 40px;
    background: #D31145;
    border-radius: 4px;
}

.fab-profile-section-update-button-disable {
    opacity: 0.4;
}

.fab-profile-section-update-button-text {
    width: 114px;
    height: 19px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-wrap: nowrap;
    color: #FFFFFF;
    white-space: nowrap;
}

/* Job preference */
.fab-profile-section-jp {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 40px 32px;
    gap: 24px;
    width: 800px;
    height: 680px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
}

.fab-profile-section-jp-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    /* width: 720px;
    height: 168px; */
    width: 100%;
    height: auto;
    margin-top: 24px;
}

.fab-profile-section-checkmark-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    height: auto
}

.fab-profile-section-checkmark-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    /* width: 210px;
    height: 24px; */
    width: 100%;
    height: auto;
}

.fab-profile-section-checkmark-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2px;
    gap: 8px;
    /* width: 24px;
    height: 24px; */
    width: 100%;
    height: auto;
}

.fab-profile-section-checkmark-header {
    width: 100%;
    height: auto;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #18181B;
}

.fab-profile-section-checkmark-desc-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 40px;
    width: 100%;
    height: auto;
}

.fab-profile-section-checkmark-desc-text {
    /* width: 679px;
    height: 40px; */
    width: 100%;
    height: auto;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3F3F46;
}

.fab-profile-section-exp-sal-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 450px;
    /* height: 68px; */
    height: auto;
}

.fab-sal-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    width: 450px;
    /* height: 40px; */
    height: auto
}

.fab-sal-amount-input {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    width: 223px;
    /* height: 40px; */
    height: auto
}

.fab-currency-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 42px;
    height: 40px;
    border-radius: 4px 0px 0px 4px;
}

.fab-currency-group-base {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 42px;
    height: 40px;
    border: 1px solid #D4D4D4;
    border-radius: 4px 0px 0px 4px;
}

.fab-amount-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 181px;
    height: 40px;
}

.fab-amount-group-base {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    width: 181px;
    height: 40px;
    border-width: 1px 1px 1px 0px;
    border-style: solid;
    border-color: #D4D4D4;
    border-radius: 0px 4px 4px 0px;
}

.fab-amount-input {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.fab-per {
    width: 24px;
    height: 19px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #18181B;
}

input[type="file"] {
    display: none;
}

.fab-marketing-info-cb-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    /* 
    width: 720px;
    height: 48px; */
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
    height: auto;
}

.fab-marketing-info-cb {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2px;
    gap: 8px;
    width: 24px;
    height: 24px;
}

.fab-marketing-info-note {
    /* width: 680px;
    height: 48px; */
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #18181B;
    max-width: 680px;
    height: auto;
}

.fab-form-field-error-message {
    width: 100%;
    height: 20px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #DC2626;
}

/* Radio button color */
input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #D4D4D4;
    outline: none;
    position: relative;
    cursor: pointer;
}

input[type="radio"]:checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #D31145;
}

input[type="radio"]:checked {
    border-color: #D31145;
}

/* Checkbox color */
input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    position: relative;
    background: #FFFFFF;
    border: 1px solid #D4D4D4;
    border-radius: 2px;
    cursor: pointer;
}

input[type="checkbox"]::before {
    content: url('/src/assets/svg/checkbox-tick.svg');
    font-size: 16px;
    color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

input[type="checkbox"]:checked {
    border-color: #D31145;
    background-color: #D31145;
}

input[type="checkbox"]:checked::before {
    color: #FFFFFF;
}

.react-datepicker-wrapper {
    width: 100%;
}

.fab-profile-section-item {
    padding: 24px 40px;
    background-color: #FFF;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.fab-per-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    width: 100%;
}

.fab-profile-section-resume {
    margin-bottom: 50px;
}

.fab-profile-mobile-menu {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.fab-profile-mobile-menu .fab-sidebard-mobile-dropdown {
    margin-left: 32px;
    margin-right: 32px;
}

.scrollable-div {
    overflow-x: scroll;
    white-space: nowrap;
    width: 100%;
    padding-left: 32px;
    height: auto
}

.scrollable-div a {
    margin-right: 32px;
    padding: 16px;
    height: 100%;
}

@media(max-width: 1068px) {
    .fab-profile-section-row {
        flex-direction: column;
    }
}

@media(max-width: 968px) {
    .fab-profile-vertical-bar {
        display: none;
    }

    .fab-profile-layout {
        margin-left: 0;
    }

    .fab-profile-section-dropdown-container,
    .fab-profile-section-textfield-input,
    .fab-profile-section-textfield-container,
    .fab-profile-section-field-header {
        width: 100%;
    }

    .fab-profile-mobile-active {
        display: flex
    }

    .fab-profile-mobile-active .fab-menu-mobile-items {
        padding: 0;
        gap: 0;
    }

    .fab-profile-mobile-active .fab-menu-mobile-container {
        max-height: 320px;
    }

    .fab-sidebar-dropdown-options {
        display: none;
    }
}

@media(max-width: 768px) {

    .fab-profile-section-row,
    .fab-sal-input {
        flex-direction: column;
        align-items: flex-start;
    }

    .fab-amount-group,
    .fab-sal-amount-input,
    .fab-amount-input {
        width: 100%;
    }

    .fab-marketing-info-cb-container input[type="checkbox"] {
        width: 30px;
    }

}

@media(max-width: 568px) {
    .fab-profile-section-header {
        font-size: 20px;
    }

    .fab-profile-section-item {
        padding: 16px;
    }

    .fab-profile-container {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .fab-profile-section-update-button-container button,
    .fab-profile-section-exp-sal-container,
    .fab-sal-input {
        width: 100%;
    }

    .fab-marketing-info-cb-container input[type="checkbox"] {
        width: 50px;
    }
}

@media(max-width: 400px) {
    .fab-profile-mobile-menu .fab-sidebard-mobile-dropdown {
        margin-left: 16px;
        margin-right: 16px;
    }
}