.fb-not-found-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    flex-direction: column;
    row-gap: 40px;
}

.fb-not-found-text-message {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 15px;
}

.fab-not-found-header {
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    font-size: 48px;
    font-weight: 600;
    line-height: 64px;
    text-align: center;
    color: #18181B;
}

.fab-not-found-text {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #18181B;
    word-wrap: break-word;
    width: 100%;
    max-width: 490px;
}

.fab-not-found-button {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 15px;
    margin-top: 20px;
    margin-bottom: 30px
}

.fab-not-found-customer-care {
    text-decoration: underline;
    color: #18181B;
}

@media(max-width: 390px) {
    .fab-not-found-button button {
        width: 80%
    }
}