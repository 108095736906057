.fab-input-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #D4D4D4;
    width: fit-content;
    position: relative;
    padding-right: 16px;
    border-radius: 4px;
    background-color: #FFF;
}

.fab-input-wrapper:focus-within {
    border-color: #1D4ED8;
}

.fab-input {
    border: none;
    width: 100%;
    padding: 12px 16px;
    border-radius: 4px;
}

.fab-input:focus {
    outline: none;
}

.fab-input-close {
    cursor: pointer;
}