.toast-container {
    position: fixed;
    top: 96px;
    right: 24px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.line {
    width: 3px;
    height: 52px;
    gap: 0px;
    border-radius: 2px 0px 0px 0px;
    opacity: 0px;
}

.toast {
    display: flex;
    margin-bottom: 10px;
    max-width: 400px;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    padding: 12px 20px;
    gap: 10px;
    position: absolute;
    background-color: #FFFFFF;
    border: 1px solid #F5F5F5;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    width: 400px;
    /* height: 76px; */
}

.line.error {
    background: rgba(185, 28, 28, 1);
}

.line.info {
    background: rgba(37, 99, 235, 1);
}

.line.success {
    background: rgba(5, 150, 105, 1);
}

.line.warning {
    background: rgba(234, 179, 8, 1);
}


.icon {
    margin-right: 10px;
}

.content {
    flex-grow: 1;
    overflow: auto;
}

.header {
    font-weight: bold;
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: rgba(24, 24, 27, 1);
}

.close-btn {
    cursor: pointer;
    margin-left: 30px;
}

.message {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(63, 63, 70, 1);
    overflow-wrap: break-word;
}

@media (max-width: 1024px) {
    .toast-container {
        top: 50px;
        right: 50%;
        align-items: center;
        transform: translate(50%, -50%);
        z-index: 10;
    }

    .toast {
        width: 343px;
    }
}

@media(max-width: 767px) {
    .toast {
        width: 340px;
    }
}

@media(max-width: 356px) {
    .toast {
        width: 300px;
    }
}