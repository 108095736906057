.fab-jobs-no-data {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px 20px 0px;
    gap: 32px
}

.fab-no-results-text {
    font-family: 'Poppins', system-ui, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #18181B;
    margin-bottom: -25px;
}

.fab-try-again-text {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #3F3F46;
}

@media (max-width: 992px) {
    .fab-try-again-text {
        font-size: 12px;
    }
}