.fab-slide-drawer {
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
    padding: 0;
    width: 100%;
    height: 60%;
    z-index: 400;
    background-color: white;
    border: 1px solid #E5E5E5;
    border-radius: 8px 8px 0px 0px;
    box-shadow: 0px 10px 10px 0px #0000000A;
    box-shadow: 0px 20px 25px -5px #0000001A;
    overflow: auto;
}

.fab-slider-container {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}