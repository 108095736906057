.fab-dropdown-svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none
}

.fab-drodown {
    width: 100%;
    height: 100%;
}

.fab-drodown-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 1px solid #D4D4D4;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #FFF;
    height: 100%;
}

.fab-drodown-container-m {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 1px solid #D31145;
    padding: 8px 16px;
    border-radius: 4px;
    background-color: #FBE7EC;
    height: 100%;
    cursor: pointer;
}


.fab-dropdown-menu {
    position: absolute;
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid #D4D4D4;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 250px;
    max-height: 200px;
    height: 150px;
    overflow-y: scroll;
    z-index: 1;
    background-color: #FFF;
}

.fab-dropdown-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    width: 100%;
}

.fab-dropdown-menu-item {
    cursor: pointer;
    justify-content: flex-start;
    gap: 12px;
}

.fab-dropdown-menu-item:hover {
    background-color: #F5F5F5;
}

.fab-dropdown-menu-item svg {
    display: none;
}

.fab-dropdown-check {
    height: 20px;
    width: 20px;
    accent-color: #D31145;
}

.fab-dropdown-text {
    max-width: 274px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 30px;
    align-items: center;
}

.fab-circle-badge {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: 15px;
    color: #fff;
    line-height: 25px;
    text-align: center;
    background: #D31145;
    vertical-align: middle;
    margin-left: 5px;
}

.fab-dropdown-text-m {
    display: inline-flex;
    max-width: 274px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 30px;
    align-items: center;
}

@media(max-width: 992px) {
    .fab-dropdown-text {
        max-width: 264px;
    }
}

@media(max-width: 678px) {
    .fab-dropdown-text {
        max-width: 210px;
    }
}