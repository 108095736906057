html {
    box-sizing: border-box;
    font-size: 100%;
}

html,
body {
    height: 100%;
    width: 100%;
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', system-ui, Arial, sans-serif;
}

h1,
h2,
h3 {
    font-weight: 700;
    line-height: 1.1;
    margin-top: 0;
    font-family: 'Poppins', system-ui, sans-serif;
}

p {
    margin-top: 0;
    font-family: 'Lato', system-ui, Arial, sans-serif;
}

a,
a:visited,
a:active {
    text-decoration: none;
}

input {
    font-size: 16px;
    max-width: 100%;
    width: 100%;
}


.fab-container {
    margin: auto 112px;
    padding: auto 15px;
}

.fab-featured-pill {
    background-color: #D31145;
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 2px;
    color: #FFFFFF;
    text-transform: uppercase;
}

@media(max-width: 868px) {
    .fab-container {
        margin: auto 32px;
    }
}

@media(max-width: 568px) {
    .fab-container {
        margin: auto 16px !important;
    }
}

@media (min-width:992px) and (max-width:1448px) {
    .fab-container {
        margin: auto 16px;
    }
}