.fab-verify-mobile-content {
    height: calc(100dvh - 350px);
}

.fab-verify-mobile-container {
    background-color: #FAFAFA;
}

.fab-verify-mobile-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 60px;
    flex-direction: column;
    row-gap: 40px;
}

.fab-verify-mobile-header-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 16px;
}

.fab-verify-mobile-header {
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
    color: #18181B;
}

.fab-verify-mobile-sub-heading {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #18181B;
    word-wrap: break-word;
    width: 100%;
    max-width: 490px;
}

.fab-verify-mobile-input-wrapper {
    border: 1px solid #D4D4D4;
    border-radius: 4px;
    background-color: #FFF;
    display: flex;
    justify-content: flex-start;
}

.fab-verify-mobile-label {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #18181B;
}

.fab-verify-mobile-number-country-wrapper {
    align-items: center;
    display: flex;
}

.fab-verify-mobile-number-country-icon {
    font-size: 16px;
    margin-left: 10px;
}

.fab-verify-mobile-number-country {
    outline: none;
    padding-left: 8px;
    margin-right: 8px;
    border: none;
    border-radius: 4px 0 0 4px;
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #3F3F46;
}

.fab-verify-mobile-input {
    padding: 12px 16px;
    margin-right: 40px;
    border: none;
    border-radius: 0 4px 4px 0;
    width: 100%;
    border-left: 1px solid #D4D4D4;
}

.fab-verify-mobile-input:focus {
    outline: none;
}

.fab-verify-mobile-input-wrapper input[type="number"]::-webkit-inner-spin-button,
.fab-verify-mobile-input-wrapper input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.fab-verify-mobile-input-wrapper input[type="number"] {
    -moz-appearance: textfield;
}

.fab-verify-mobile-send-button {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    text-align: center;
    color: #FFFFFF;
}

.fab-verify-mobile-number-edit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
}

.fab-verify-mobile-number-edit-text {
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #18181B;
}

.fab-verify-mobile-otp-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px
}

.fab-verify-mobile-otp-input {
    width: 58px;
    height: 50px;
    text-align: center;
    margin-right: 10px;
    font-family: 'Lato', system-ui, Arial, sans-serif;
    color: #18181B;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 0px 2px 0px 2px;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #D4D4D4;
    background: #FFFFFF;
}

.fab-verify-mobile-otp-input:focus {
    border: 1px solid #1D4ED8
}

.fab-verify-mobile-timer-text {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.fab-verify-mobile-timer-resend-text {
    color: rgba(220, 38, 38, 1);
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.fab-verify-mobile-error-message {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(220, 38, 38, 1);
}

.fab-verify-mobile-error-border {
    border: 1px solid rgba(185, 28, 28, 1)
}

.fab-verify-mobile-timer-error-text {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(220, 38, 38, 1);
}

.fab-verify-mobile-timer-otp-error-text {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(220, 38, 38, 1);
}

@media(max-width: 569px) {
    .fab-verify-mobile-content {
        margin: 40px 20px;
    }
}

@media(max-width: 390px) {
    .fab-verify-mobile-header {
        font-size: 30px;
    }
}

@media(max-width: 358px) {
    .fab-verify-mobile-otp-container {
        gap: 0px;
    }
}

@media(max-width: 400px) {
    .fab-verify-mobile-content {
        height: calc(100dvh - 100px);
    }
}