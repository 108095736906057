.fab-location-modal-mobile {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.fab-location-back-wrapper {
    border: none;
    background-color: inherit;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 8px;
}

.fab-location-modal-header-mobile {
    padding: 8px 8px 16px 8px;
    gap: 8px;
    background: #FFFFFF;
}

.fab-location-back-text {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #3F3F46;
    margin-left: 12px;
}

.fab-location-back-icon {
    width: 7.5px;
    height: 13.75px;
    top: 3.13px;
    left: 6.25px;
    color: #3F3F46;
}

.fab-location-mobile-header {
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #18181B;
}

.fab-location-modal-content-mobile {
    margin: 15px;
}

.fab-location-item-mobile {
    padding: 8px 8px 8px 0px;
    gap: 16px;
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #18181B;
}