.fab-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.60);
  overflow-y: auto;
  z-index: 9999999;
}

.fab-modal-body {
  position: relative;
  height: 100%
}

.fab-progressive-application {
  display: none;
  justify-content: flex-end;
}

.modal-active {
  display: flex;
}

.fab-modal-verification {
  overflow: auto;
  height: 100vh;
  max-width: 600px;
  width: 100%;
  background-color: #FFFFFF;
  box-sizing: border-box;
}

.fab-verification-header {
  padding: 80px 32px;
}

.fab-verification-header h3 {
  color: #18181B;
  font-weight: 600;
  font-size: 24px;
  font-family: 'Poppins', system-ui, Arial, sans-serif;
  margin-bottom: 12px;
}

.fab-verification-user-name {
  font-size: 18px;
  font-weight: 700;
  color: #3F3F46;
  margin-bottom: 4px;
}

.fab-verification-subtext {
  font-size: 16px;
  font-weight: 400;
  color: #3F3F46;
  margin-top: 4px;
}

.application-verification-text {
  color: #222222;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 12px;
}

.application-verification-text span {
  color: #5A6287;
}

.application-verification-sutext {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 32px;
}

.application-verification-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.application-verification-sutext {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 32px;
}

.application-input-label {
  font-size: 16px;
  font-weight: 500;
  color: #18181B;
  margin-bottom: 24px;
}

.application-inputs {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 16px;
}

.application-verification-input {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  background-color: #FFFFFF;
  border: 1px solid #D4D4D4;
  padding: 12px 16px;
  -moz-appearance: textfield;
}

.application-verification-input:focus {
  border: 1px solid #1D4ED8 !important;
  outline: #1D4ED8;
}

.application-otp-error {
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #D31145;
  margin-top: 15px
}

.application-verification-input::-webkit-inner-spin-button,
.application-verification-input::-webkit-outer-spin-button,
.application-verification-input {
  -webkit-appearance: none;
  margin: 0;
}

.application-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.application-btn-resend,
.application-btn {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;
  border-radius: 4px;
  border: none;
}

.application-btn-verify {
  padding: 14px;
  margin-bottom: 32px;
}

.application-verfiy-count {
  font-size: 14px;
  font-weight: 400;
  color: #18181B;
  margin-bottom: 16px;
}

.application-resend-count {
  color: #D31145;
}

.application-btn-resend {
  opacity: 0.4;
  cursor: not-allowed;
  padding: 10.5px 20.5px;
}

.fab-verification-resend-disabled {
  background-color: #FFF;
}

.fab-verification-error {
  color: #D31145;
  font-size: 16px;
  font-weight: 400;
  max-width: 400px;
  padding-left: 32px;
  padding-right: 32px;
  text-align: center;
  margin-top: 32px;
  line-height: 24px;
}

/* Wizard */
.application-progress-wrapper {
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 24px;
}

.employment-status-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.employment-status-wrapper label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 16px;
  cursor: pointer;
}

.employment-status-wrapper {
  padding: 32px 19px;
}

.application-wizard-wrapper {
  margin-bottom: 25px;
  position: fixed;
  top: 0;
  background-color: #FFF;
  width: -webkit-fill-available;
  padding-top: 100px;
  padding-bottom: 50px;
  z-index: 2
}

.wizard-progress {
  display: flex;
  width: 100%;
  table-layout: fixed;
  position: relative;
}

.wizard-progress .step {
  text-align: center;
  vertical-align: top;
  overflow: visible;
  position: relative;
  font-size: 12px;
  width: 100%;
}

.wizard-progress .step span {
  margin-right: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #71717A;
}

.wizard-progress .complete span {
  color: #0A9A6F;
}

.wizard-progress .in-progress span {
  color: #D31145;
  font-weight: bold;
}

.wizard-circle {
  display: inline-block;
  border: 1px solid #CFCFCF;
  background-color: #FFE;
  border-radius: 18px;
  height: 16px;
  width: 16px;
  position: absolute;
  top: -20px;
  left: 50%;
  margin-left: -18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wizard-circle div {
  background: #CFCFCF;
  height: 11.2px;
  width: 11.2px;
  border-radius: 50%;
}

.wizard-progress .step:not(:last-child):before {
  content: "";
  display: block;
  position: absolute;
  left: 45%;
  top: -12px;
  background-color: #E5E5E5;
  height: 1px;
  width: 100%;
}

.wizard-progress .complete::before {
  background-color: #0A9A6F !important;
}

.wizard-progress .in-progress .wizard-circle {
  border: 1px solid #D31145;
}

.wizard-progress .complete .wizard-circle {
  border: 1px solid #0A9A6F;
}

.wizard-progress .in-progress .wizard-circle div {
  background-color: #D31145;
}

.wizard-progress .complete .wizard-circle div {
  background-color: #FFF;
}

.wizard-progress .complete .wizard-circle div::before {
  content: "\2713";
  color: green;
  font-size: 12px;
  position: absolute;
  top: 6px;
  left: 7px;
  /* height: 8px; */
  /* width: 6px; */
  transform: translate(-50%, -50%);
  /* background: #FFF; */
  display: inline-block;
}


.wizard-progress .step.in-progress .node {
  border-color: #FA4094;
  background-color: #FA4094;
}

.wizard-progress .complete .node {
  border-color: #0A9A6F;
  background-color: #0A9A6F;
}

.fab-modal-education {
  height: 100%;
  max-width: 600px;
  width: 100%;
  background-color: #FFFFFF;
}

.fab-application-education {
  padding: 24px 32px;
  margin-top: 150px;
  max-height: calc(100dvh - 161.53px);
  overflow-y: auto;
}

.fab-application-education-margin {
  margin-top: 60px;
  padding: 24px 32px;
  overflow-y: auto;
  max-height: calc(100dvh - 161.53px);

}

.application-modal-title {
  font-size: 24px;
  font-weight: 600;
  color: #18181B;
  font-weight: 'Poppins', system-ui, Arial, sans-serif;
  margin-bottom: 24px;
}

.application-content {
  height: calc(100dvh - 161.53px);
  max-height: calc(100dvh - 161.53px);
  overflow-y: auto;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 50px;
}

.fab-application-employment-status {
  display: flex;
  flex-direction: column;
  gap: 24px
}

.fab-application-employment-status label {
  color: #18181B;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  gap: 16px;
  cursor: pointer;
}

.fab-application-employment-status input[type='radio'] {
  height: 20px;
  width: 20px;
  cursor: pointer;
  accent-color: #D31145;
}

.fab-modal-application-close {
  position: fixed;
  top: 32px;
  right: 32px;
  z-index: 3;
  cursor: pointer;
}

.fab-modal-application-back {
  position: fixed;
  top: 32px;
  left: 32px;
  z-index: 3;
  cursor: pointer;
  display: none;
}

.application-content {
  display: none;
  height: calc(100dvh - 161.53px);
  max-height: calc(100dvh - 161.53px);
  overflow-y: auto;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 50px;
}

.application-content-active {
  display: block;
}

.language-checkbox-wrapper {
  padding-bottom: 16px;
  padding-top: 16px;
}

.language-checkbox-wrapper:not(:last-child) {
  border-bottom: 1px solid #E5E5E5;
}

.language-checkbox-wrapper label {
  font-family: 'Lato', system-ui, Arial;
  color: #18181B;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 12px;
  cursor: pointer;
  margin: 0;
}

.application-checkbox {
  appearance: auto;
  accent-color: #D31145;
  height: 18px;
  width: 18px;
  margin: 0 !important;
}

.application-lang-proficiency {
  margin-left: 56px;
  margin-right: 56px;
  margin-top: 20px;
}

.application-proficiency-label {
  color: #18181B;
  font-size: 16px;
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-weight: 700;
}

.application-lang-radio-wrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 80px;
  margin-top: 8px;
}

.application-lang-radio-wrapper label {
  display: flex;
  align-items: center;
  column-gap: 16px;
  justify-content: flex-start;
  margin: 0;
}

.application-lang-radio-wrapper input[type="radio"] {
  height: 20px;
  width: 20px;
  accent-color: #D31145;
}

.application-lang-radio,
.application-employment-status-input {
  appearance: auto !important;
  display: block !important;
  height: 16px;
  width: 16px;
  accent-color: #FA4094;
  margin: 0 !important;
  cursor: pointers;
}

.application-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #E5E5E5;
  background-color: #FFF;
  /* z-index: 999999; */
}

.application-footer-space-between {
  justify-content: space-between
}

.application-footer-language {
  justify-content: flex-end;
}

.application-btn-next {
  padding: 14.5px 42.5px
}

.application-btn-back {
  background-color: #FFF;
  color: #FA4094;
  border: 1px solid #FA4094;
  padding: 14.5px 42.5px;
  display: none;
}

.application-btn-back:hover {
  background-color: #FFEEF6;
}

.application-lang-active {
  display: block !important;
}

.application-progress-wrapper {
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 24px;
}

.employment-status-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.employment-status-wrapper label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 16px;
  cursor: pointer;
}

.employment-status-wrapper {
  padding: 32px 19px;
}

.modal-open-noscroll {
  overflow: hidden;
}

.apply-method-option {
  padding: 16px 20px;
  border: 1px solid #D4D4D4;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.apply-method-option span {
  display: flex;
  align-items: center;
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #18181B;
  column-gap: 8px;
}

.apply-method-option input[type="radio"] {
  display: inline-block;
  appearance: auto;
  height: 20px;
  width: 20px;
  margin: 0;
  accent-color: #FA4094;
}

.apply-method-option input[type="radio"]:checked+label {
  background-color: #FA4094;
  border-color: #FA4094;
}

.apply-method-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.apply-method-header h3 {
  font-family: 'Poppins', system-ui, Arial, sans-serif;
  font-family: 24px;
  font-weight: 600;
  color: #18181B;
  margin: 0;
}

.apply-method-close {
  cursor: pointer;
}

.apply-method-subtext {
  padding: 0 15px;
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #18181B;
  margin: 0;
  margin-top: 12px;
}

.apply-generate-paragraph,
.apply-generate-subtext {
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-size: 16px;
  color: #18181B;
  font-weight: 400;
  padding: 0 15px;
}

.apply-generate-paragraph {
  margin-top: 12px;
}

.apply-generate-subtext {
  margin-top: 8px;
}

#modalGenerateResume .modal-body {
  border-radius: 8px !important;
}

.apply-generate-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 12px;
  margin-top: 16px;
}

.apply-generate-buttons button {
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  padding: 14.5px 16px;
  border-radius: 4px;
}

.apply-generate-btn-upload {
  border: 1px solid #FA4094;
  color: #FA4094;
  background-color: #FFF;
}

.apply-generate-btn-upload {
  border: 1px solid #FA4094;
  color: #FA4094;
  background-color: #FFF;
}

.apply-generate-btn-upload:hover {
  background-color: #FFEEF6;
}

.apply-generate-btn-new {
  background-color: #FA4094;
  color: #FFF;
  border: none;
}

.apply-generate-btn-new:hover {
  background-color: #DE116E;
}

@media(max-width: 568px) {
  .application-lang-radio-wrapper {
    column-gap: 10px;
  }

  .fab-modal-container {
    height: auto;
  }

  .fab-modal-application-back {
    display: block;
    left: 16px;
  }

  .fab-modal-application-close {
    right: 16px;
  }

  .fab-progressive-button-back {
    display: none;
  }

  .fab-modal-body .application-footer button:last-child,
  .application-btn-verify {
    width: 100%;
  }

  .fab-modal-body .application-footer,
  .fab-application-education {
    padding: 16px;
  }
}

@media(max-width: 386px) {
  .application-verification-wrapper {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0;
  }

  .fab-verification-header {
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 10px;
  }

  .application-btns {
    width: 100%;
  }
}

@media(max-width: 767px) {
  .fab-modal-verification {
    height: 100vh;
  }

  .fab-verification-header {
    padding-bottom: 10px;
  }

  .fab-verification-error {
    margin-top: 10px;
  }

  .application-verification-text {
    margin-top: 15px;
  }
}