.fab-latest-jobs {
    margin-bottom: 48px;
}

.fab-featured-comapanies {
    margin-bottom: 40px;
    margin-top: 40px;
    padding-top: 35px;
}

.fab-home-feature-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

.fab-featured-card-item {
    height: auto;
    border: 1px solid #D4D4D4;
    border-radius: 4px;
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: all 250ms ease-in-out;
    cursor: pointer;
}

.fab-featured-card-item:hover {
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
}

.fab-featured-image-wrapper {
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid #E5E5E5;
}

.fab-featured-image {
    width: 100%;
    height: 0;
    padding-bottom: 75%;
    background-size: cover;
    background-position: center;
    border-radius: 4px 4px 0px 0px;
}

.fab-featured-details {
    width: 100%;
    height: 120px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.fab-featured-company {
    color: #18181B;
    font-size: 18px;
    font-weight: 700;
    width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.fab-featured-location {
    font-size: 14px;
    color: #3F3F46;
    font-weight: 400;
}

@media(max-width: 1180px) {
    .fab-featured-company {
        width: 140px;
    }
}

@media(max-width: 1024px) {
    .fab-home-feature-cards {
        grid-template-columns: 1fr 1fr;
    }

    .fab-featured-company {
        width: 265px;
    }
}

@media(max-width: 828px) {
    .fab-featured-company {
        width: 165px;
    }
}

@media(max-width: 568px) {
    .fab-featured-comapanies {
        padding-top: 30px;
    }

    .fab-home-feature-cards {
        grid-template-columns: 1fr;
    }
}

@media(max-width: 600px) {
    .fab-featured-company {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 400px;
    }

    .fab-home-feature-cards {
        grid-template-columns: 1fr;
    }
}

@media(max-width: 468px) {
    .fab-home-feature-cards {
        grid-gap: 15px;
    }

    .fab-featured-company {
        white-space: initial;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        width: 310px;
    }
}

@media(max-width: 375px) {
    .fab-home-feature-cards {
        grid-gap: 15px;
    }

    .fab-featured-company {
        width: 250px;
    }
}