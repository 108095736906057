.fb-ed-section {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 40px 32px;
    gap: 24px;
    width: 800px;
    /* height: 772px; */
    height: auto;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
}

.fb-ed-form-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 19px;
    width: 100%;
    /* width: 720px; */
    /* height: 500px; */
    height: auto;
    background: #FDFCFC;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
}

.fb-form-section-dropdown-error {
    border: 1px solid #DC2626;
    border-radius: 4px;
}

.fab-ed-list-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 12px 8px 12px 16px;
    gap: 12px;
    width: 100%;
    /* height: 96px; */
    height: auto;
    margin-top: 24px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
}

.fab-ed-list-container ul {
    list-style: none;
    padding: 0;
    width: 100%;
}

.fab-ed-list-container li {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
}

.fab-ed-list-container .list-icon {
    margin-right: 5px;
}

.fab-ed-list-container li:not(:last-child)::before {
    content: '';
    position: absolute;
    top: 40px;
    top: calc(50% + 5px);
    left: 5px;
    width: 2px;
    height: 115%;

    border: 1.5px solid #D31145;
}

.fab-ed-list-container li:last-child {
    margin-bottom: 0;
}

.fab-ed-list-title {
    /* width: 50px;
    height: 24px; */

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* text-wrap: nowrap; */

    color: #18181B;
}

.fab-ed-list-subtitle {
    /* width: 73px;
    height: 24px; */

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* text-wrap: nowrap; */

    color: #3F3F46;
}

.fab-ed-list-date-range {
    /* width: 150px;
    height: 24px; */

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* text-wrap: nowrap; */

    color: #3F3F46;
}