.fab-btn {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-weight: 700;
    border-radius: 4px;
    padding: 14.5px 16px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.fab-btn--primary {
    background: #D31145;
    color: #FFF;
    transition: all 250ms ease-in-out;
}

.fab-btn--primary:hover,
.fab-btn--primary:active {
    background-color: #8C082B;
}

.fab-btn--outline {
    background-color: #FFFFFF;
    color: #D31145;
    border: 1px solid #D31145;
    transition: all 250ms ease-in-out;
}

.fab-btn--outline:hover,
.fab-btn--outline:active {
    background-color: #F6E9EA;
}

.fab-button--lg {
    padding: 14.5px 32px;
}

.fab-btn--md {
    padding: 10.5px 32px;
}

.fab-btn--sm {
    padding: 6.5px 32px;
}

.fab-password-toggle {
    background-color: transparent;
    border: none;
}

.fab-password-toggle svg {
    cursor: pointer;
}

.fab-button--disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
}