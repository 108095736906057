.fb-we-section {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 40px 32px;
    gap: 24px;
    width: 800px;
    /* height: 1006px; */
    height: auto;
    background: #FFFFFF;
    /* border: 1px solid #E5E5E5; */
    border-radius: 4px;
}

.fb-header-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    /* width: 720px;
    height: 72px; */
    width: 100%;
    height: auto;
}

.fb-header-btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 24px;
    /* width: 720px; */
    width: 100%;
    height: 40px;
}

.fb-section-title {
    /* width: 205px;
    height: 28px; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-wrap: nowrap;
    color: #D31145;
    white-space: nowrap;
}

.fb-add-btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 12px;
    width: 94px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D31145;
    border-radius: 4px;
}

.fb-add-btn-text {
    /* width: 30px;
    height: 19px; */
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #D31145;
}

.fb-description {
    /* width: 373px; */
    width: auto;
    /* height: 24px; */
    height: auto;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* text-align: center; */
    text-align: left;
    color: #3F3F46;
}

.fb-we-form-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 19px;
    /* width: 720px; */
    /* height: 566px; */
    height: auto;
    width: 100%;
    background: #FDFCFC;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
}

.fb-form-section-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    /* width: 672px; */
    width: 100%;
    /* height: 68px; */
    height: auto;
}

.fb-form-section-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    /* width: 672px; */
    width: auto;
    height: 24px;
}

.fb-form-section-header-text {
    /* width: 64px; */
    width: auto;
    height: 24px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-wrap: nowrap;
    color: #18181B;
}

.fb-form-section-header-text span {
    color: #D31145;
}

.fb-form-section-input {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    /* width: 672px;
    height: 40px; */
    width: 100%;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #D4D4D4;
    border-radius: 4px;
}

.fb-form-section-input-error {
    border: 1px solid #DC2626
}

.fb-form-section-input-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-height: 40px;
}

.fb-form-wp-from-to-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    /* width: 480px;
    height: 68px; */
    width: 100%;
    height: auto;
}

.fb-form-wp-from-to-container-height {
    height: auto;
}

.fb-form-wp-date-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 100%;
    height: auto;
}

.fb-form-wp-date-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
    height: 24px;
}

.fb-form-wp-date-header-text {
    width: 44px;
    height: 24px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-wrap: nowrap;
    color: #18181B;
}

.fb-form-wp-date-header-text span {
    color: #D31145;
}

.fb-form-wp-date-picker-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    /* width: 234px; */
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    /* border: 1px solid #D4D4D4; */
    border: none;
    /* border-radius: 4px; */
}

.fb-form-wp-date-picker-container>input[type="month"]::-webkit-calendar-picker-indicator {
    background-image: url('/src/assets/svg/date-picker.svg');
    width: 25px;
    height: 25px;
    padding: 12px;
    position: relative;
    left: 30px;
    right: 0;
    top: 0;
    bottom: 0;
}

.fb-form-wp-date-picker-input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    width: 100%;
    height: 40px;
    border: 1px solid #D4D4D4;
    /* border: none; */
    border-radius: 4px;
}

.fb-form-wp-date-picker-input-wrapper-error {
    border: 1px solid #DC2626;
}

.fb-form-wp-date-picker-input-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* color: #A1A1AA; */
}

.fb-form-wp-cwh-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 186px;
    height: 24px;
}

.fb-form-wp-cwh-cb-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2px;
    gap: 8px;
}

.fb-form-wp-cwh-cb {
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    border: 1px solid #D4D4D4;
    border-radius: 2px;
}

.fb-form-wp-cwh-cb-text {
    width: 146px;
    height: 24px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-wrap: nowrap;
    color: #18181B;
    white-space: nowrap;
}

.fb-form-job-res-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    /* width: 672px; */
    width: 100%;
    height: 134px;
}

.fb-form-job-res-ta-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0px;
    width: 100%;
}

.fb-form-job-res-ta {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 0px 8px 16px;
    gap: 8px;
    /* width: 672px; */
    width: 100%;
    height: 96px;
    background: #FFFFFF;
    border: 1px solid #D4D4D4;
    border-radius: 4px;
}

.fb-form-job-res-ta-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.fb-save-cancel-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    gap: 192px;
    /* width: 672px; */
    width: 100%;
    height: 100%;
}

.fb-cancel-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 12px;
    width: 49px;
    height: 40px;
    border-radius: 4px;
    background: #FFFFFF;
}

.fb-cancel-btn-text {
    width: 49px;
    height: 19px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #D31145;
}

.fb-save-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 12px;
    width: 128px;
    min-width: 128px;
    height: 40px;
    background: #D31145;
    border-radius: 4px;
}

.fb-save-btn-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
}

.fb-list-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 12px 8px 12px 16px;
    gap: 12px;
    width: 100%;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    margin-top: 24px;
}

.fb-list-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    width: 696px;
    /* height: 72px; */
}

.fb-list-contents-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    /* height: 72px; */
}

.fb-list-title {
    /* width: 50px;
    height: 24px; */

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* text-wrap: nowrap; */

    color: #18181B;
}

.fb-list-subtitle {
    /* width: 73px;
    height: 24px; */

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* text-wrap: nowrap; */

    color: #3F3F46;
}

.fb-list-date-range {
    /* width: 150px;
    height: 24px; */

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* text-wrap: nowrap; */

    color: #3F3F46;
}

.fb-list-actions-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    width: 84px;
    height: 40px;
}

.fb-list-btn-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px;
    gap: 8px;

    width: 40px;
    height: 40px;

    background: #FFFFFF;
}

.fb-list-line {
    position: relative;
    width: 50px;
    height: 0px;
    left: 0px;
    top: 0px;

    border: 1.5px solid #D31145;
    transform: rotate(90deg);
}

.fb-list-container ul {
    list-style: none;
    padding: 0;
    width: 100%;
}

.fb-list-container li {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
}

.fb-list-container .list-icon {
    margin-right: 5px;
}

.fb-list-container li:not(:last-child)::before {
    content: '';
    position: absolute;
    top: calc(50% + 5px);
    left: 5px;
    width: 2px;

    border: 1.5px solid #D31145;
}

.fb-list-container li:last-child {
    margin-bottom: 0;
}

@media(max-width: 768px) {
    .fb-form-wp-from-to-container {
        flex-direction: column;
    }
}

@media(max-width: 486px) {
    .fb-save-btn {
        min-width: auto;
    }

    .fb-save-cancel-container {
        align-items: center;
        gap: 0;
        justify-content: space-between;
    }
}

@media(max-width: 568px) {
    .fb-section-title {
        font-size: 20px;
    }

    .fb-add-btn-text {
        font-size: 12px;
    }

    .fb-add-btn {
        width: 84px;
        height: 40px;
    }
}