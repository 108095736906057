.fab-selected-location-content-wrapper-mobile {
    border-radius: 4px;
    margin-top: 16px;
    overflow: auto;
    height: 55vh;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.fab-selection-location-modal-content-mobile {
    margin: 12px
}


.fab-selected-location-buttons-mobile button {
    width: calc(100% - 20px);
    bottom: 0;
    position: fixed;
    box-sizing: border-box;
}

.fab-selected-location-buttons-mobile {
    margin: 10px
}

.fab-selected-locations-error-mobile {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #DC2626;
    text-align: center;
}