.fab-jobs-categories-container {
    margin-top: 50px;
}

.fab-jobs-categories-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #D31145;
}

.fab-jobs-categories {
    padding: 12px 12px;
}

.fab-category {
    margin: 10px 0;
    white-space: pre-wrap;
}

.fab-category-radio {
    accent-color: #D31145;
    border: 1px solid #D31145;
    margin-right: 8px;
    margin-top: -1px;
    vertical-align: middle;
    cursor: pointer;
}

.fab-category-radio-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #18181B;
    cursor: pointer;
    white-space: pre-wrap;
}

@media (max-width: 992px) {
    .fab-jobs-categories-container {
        display: none;
    }
}