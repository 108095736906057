.profile-select-modal {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.60);
    height: 100%;
    width: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 99;
}

.profile-select-modal.show {
    display: flex;
}

.profile-select-modal-content {
    padding: 32px 32px 24px;
    width: 100%;
    max-width: 696px;
    margin: 10% auto;
    overflow: hidden;
    background-color: #FFFFFF;
    border: 1px solid #F5F5F5;
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
}

.profile-select-modal-header {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 16px;
}

.profile-select-modal-title {
    font-family: 'Poppins', system-ui, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #18181B;
}

.profile-select-modal-body {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #18181B;
}

.profile-select-modal-footer {
    display: flex;
    justify-content: flex-end;
}

.cancel-btn,
.confirm-btn {
    padding: 10px 20px;
    margin-left: 10px;
}

@media (max-width: 768px) {
    .profile-select-modal-content {
        max-width: 343px;

    }

    .profile-select-modal-footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .cancel-btn,
    .confirm-btn {
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
    }
}