.fab-reset-password-wrapper {
    height: calc(100dvh - 150px);
}

.fab-rp-wrapper {
    background-color: #FAFAFA;
}

.fab-reset-password-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.fab-reset-password-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fab-reset-password-header h3 {
    font-family: 'Poppins', system-ui, Arial, sans-serif;
    color: #D31145;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
}

.fab-reset-password-subtext {
    font-family: 'Lato', system-ui, Arial, sans-serif;
    font-size: 18px;
    color: #18181B;
    font-weight: 700;
    margin-bottom: 24px;
}

.fab-reset-password-fields {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
    align-items: center;
}

.fab-reset-password-button {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.fab-reset-password-fields .fab-input-close,
.fab-reset-password-fields .fab-input-svg {
    display: none;
}

.fab-reset-password-fields .fab-input-container {
    width: 448px;
}

.fab-reset-password-fields .fab-input-wrapper {
    width: 100%;
}

@media(max-width: 568px) {
    .fab-reset-password-wrapper {
        margin-left: 30 !important;
        margin-right: 30 !important;
    }

    .fab-user-change-password {
        border-left: none;
        border-right: none;
        padding-left: 16px;
        padding-right: 16px;
    }

    .fab-reset-password-button button {
        width: 400px !important;
    }

    .fab-reset-password-fields .fab-input-container {
        width: 400px;
    }
}

@media(max-width: 420px) {

    .fab-reset-password-button button {
        width: 350px !important;
    }

    .fab-reset-password-fields .fab-input-container {
        width: 350px;
    }

    .fab-reset-password-subtext {
        font-size: 14px;
    }

}